import React, { FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, CircularProgress } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { withErrorBoundary } from '@sentry/react';
import RegistrationTextInput from '../../../common/components/RegistrationTextInput';
import RegistrationButton from '../../../common/components/RegistrationButton';
import { CompanyState, TokenInfo } from '../types';
import { callGtagEvent, setGtagVariables } from '../../../common/utils/handleGtag';
import {
  GOOGLE_CONVERSION_ID,
  GOOGLE_CONVERSION_TAG_STEP_2,
  LINKEDIN_CONVERSION_TAG_STEP_2
} from '../../../common/utils/constants';
import callLinkedinTagEvent from '../../../common/utils/handleLinkedinTag';

type CompanyNameFormProps = {
  company: CompanyState;
  setCompany: Function;
  setStep: Function;
  companyNameValidity: string;
  setCompanyNameValidity: Function;
  setShowConfetti: Function;
  companyErrors: CompanyState | null;
  setCompanyErrors: Function;
  tokenInfo: TokenInfo | null;
};

function CompanyNameForm({
  company,
  setCompany,
  setStep,
  companyNameValidity,
  setCompanyNameValidity,
  setShowConfetti,
  companyErrors,
  setCompanyErrors,
  tokenInfo
}: CompanyNameFormProps) {
  const intl: any = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e: React.SyntheticEvent) => {
    setIsLoading(true);
    e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/misc/searchcompany?company_name=${company.company_name}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );

    setIsLoading(false);

    const data = await response.json();

    if (response.status !== 200 || data.xroadError) {
      setCompanyErrors({
        ...companyErrors,
        company_name: intl.formatMessage({
          id: 'failed_to_name_check',
          defaultMessage:
            'Name could not be checked, you may proceed but have in mind you might need to change company name.'
        })
      });
      toast.success(
        intl.formatMessage({
          id: 'failed_to_name_check',
          defaultMessage:
            'Name could not be checked, you may proceed but have in mind you might need to change company name.'
        }),
        {
          iconTheme: {
            primary: '#ffc107',
            secondary: 'white'
          }
        }
      );
      return setCompanyNameValidity('warning');
    }

    if (data.exactMatch?.length) {
      setCompanyErrors({
        ...companyErrors,
        company_name: intl.formatMessage({
          id: 'name_not_available_message',
          defaultMessage:
            "Sorry, this name is already taken. But don't be sad, try another name for your company."
        })
      });
      toast.error(
        intl.formatMessage({
          id: 'name_not_available_message',
          defaultMessage:
            "Sorry, this name is already taken. But don't be sad, try another name for your company."
        })
      );
      return setCompanyNameValidity('error');
    }

    if (data.similar?.length) {
      setCompanyErrors({
        ...companyErrors,
        company_name: intl.formatMessage({
          id: 'similar_name_exists',
          defaultMessage:
            'Similar company name exists in registry. You may proceed, but it might be needed to change company name.'
        })
      });
      toast.success(
        intl.formatMessage({
          id: 'similar_name_exists',
          defaultMessage:
            'Similar company name exists in registry. You may proceed, but it might be needed to change company name.'
        }),
        {
          iconTheme: {
            primary: '#ffc107',
            secondary: 'white'
          }
        }
      );
      return setCompanyNameValidity('warning');
    }

    if (data.request?.length) {
      setCompanyErrors({
        ...companyErrors,
        company_name: intl.formatMessage({
          id: 'requested_name_exists',
          defaultMessage: 'Request for same company name exists. Hurry up to claim it for yourself.'
        })
      });
      toast.success(
        intl.formatMessage({
          id: 'requested_name_exists',
          defaultMessage: 'Request for same company name exists. Hurry up to claim it for yourself.'
        })
      );
      return setCompanyNameValidity('success');
    }

    if (data.changed?.length) {
      setCompanyErrors({
        ...companyErrors,
        company_name: intl.formatMessage({
          id: 'name_available_message',
          defaultMessage:
            'Congratulations, this name is not taken (yet). Get it before someone else does it.'
        })
      });
      toast.success(
        intl.formatMessage({
          id: 'name_available_message',
          defaultMessage:
            'Congratulations, this name is not taken (yet). Get it before someone else does it.'
        })
      );
      return setCompanyNameValidity('success');
    }

    setCompanyErrors({
      ...companyErrors,
      company_name: intl.formatMessage({
        id: 'name_available_message',
        defaultMessage:
          'Congratulations, this name is not taken (yet). Get it before someone else does it.'
      })
    });
    toast.success(
      intl.formatMessage({
        id: 'name_available_message',
        defaultMessage:
          'Congratulations, this name is not taken (yet). Get it before someone else does it.'
      })
    );
    return setCompanyNameValidity('success');
  };

  return (
    <Box
      className="w-full md:max-w-max flex flex-col items-center md:items-start"
      style={{ maxWidth: '411px' }}>
      <p className="registration-text-large-blue text-center md:text-left">
        <FormattedMessage id="check_name_availability" defaultMessage="Check name availability" />
      </p>
      <p className="mt-5 registration-text-medium-gray">
        <FormattedMessage
          id="step_1_subtitle"
          defaultMessage="First, let's choose the name of your company and ensure it's not taken already."
        />
      </p>
      <p className="registration-text-medium-gray mt-3">
        <span>
          ⚠️{' '}
          <strong>
            <FormattedMessage id="keep_it_simple" defaultMessage="Let's keep it simple" />
          </strong>
        </span>
        <FormattedMessage
          id="special_characters_warning"
          defaultMessage="⚠️ Let's keep it simple – no fancy characters like ñ or á in your company name. The
          e-Business Register might reject them!"
        />
      </p>
      <Box
        component="form"
        className="w-full mt-5"
        onSubmit={(e: FormEvent<HTMLDivElement>) => onSubmit(e)}
        sx={{ mt: 1 }}>
        <div className="w-full flex items-center mb-5">
          <RegistrationTextInput
            style={{ maxWidth: '411px', width: '100%', marginTop: '24px' }}
            placeholder="ex. CompanioEstonia OÜ"
            id="company_name"
            label={company.is_switch ? 'Company name' : undefined}
            value={company.company_name}
            onChange={(e: InputEvent) => {
              setCompany({ ...company, company_name: (e.target as HTMLInputElement).value });
              setCompanyErrors((errors: CompanyState | null) => {
                if (!errors) {
                  return errors;
                }
                /* eslint-disable-next-line camelcase */
                const { company_name, ...restOfErrors } = errors;
                return restOfErrors;
              });
              if (companyNameValidity) {
                setCompanyNameValidity('');
              }
            }}
            success={!!companyErrors?.company_name && companyNameValidity === 'success'}
            error={!!companyErrors?.company_name && companyNameValidity === 'error'}
            warning={!!companyErrors?.company_name && companyNameValidity === 'warning'}
            helperText={companyErrors?.company_name ? companyErrors.company_name : ''}
          />
          {company.is_switch ? (
            <RegistrationTextInput
              style={{
                maxWidth: '411px',
                width: '100%',
                marginTop: '24px',
                marginLeft: company.is_switch ? '4px' : '0px'
              }}
              placeholder="12345678"
              id="registry_number"
              label={company.is_switch ? 'Registry number' : undefined}
              value={company.registry_number || ''}
              onChange={(e: InputEvent) => {
                setCompany({ ...company, registry_number: (e.target as HTMLInputElement).value });
                setCompanyErrors((errors: CompanyState | null) => {
                  if (!errors) {
                    return errors;
                  }
                  /* eslint-disable-next-line camelcase */
                  const { company_name, ...restOfErrors } = errors;
                  return restOfErrors;
                });
                if (companyNameValidity) {
                  setCompanyNameValidity('');
                }
              }}
              success={!!companyErrors?.registry_number && companyNameValidity === 'success'}
              error={!!companyErrors?.registry_number && companyNameValidity === 'error'}
              warning={!!companyErrors?.registry_number && companyNameValidity === 'warning'}
              helperText={companyErrors?.registry_number ? companyErrors.registry_number : ''}
            />
          ) : null}
        </div>
        <div className="w-full items-center md:justify-between md:flex md:mt-5 md:mb-5">
          <RegistrationButton
            disabled={isLoading}
            className="invisible md:visible registration-button-w w-full md:mr-3 md:p1 max-w-min"
            onClick={() => setStep(0)}
            id="previous_step_btn"
            variant="outlined"
            size="medium"
            direction="back"
            sx={{ maxWidth: '190px', height: '42px', marginRight: '20px', lineHeight: 1 }}>
            <FormattedMessage id="previous_step" defaultMessage="Previous step" />
          </RegistrationButton>

          {(companyNameValidity !== '' && companyNameValidity !== 'error') || company.is_switch ? (
            <RegistrationButton
              className="sm-w-full min-w-full"
              // @ts-ignore
              disabled={company.is_switch && !company.registry_number}
              onClick={() => {
                setShowConfetti(true);
                setTimeout(() => {
                  setStep(2);
                  setCompanyErrors({});
                }, 1000);
                setTimeout(() => {
                  setShowConfetti(false);
                }, 5000);
                try {
                  setGtagVariables({
                    email: tokenInfo?.email || '',
                    address: {
                      first_name: tokenInfo?.first_name || '',
                      last_name: tokenInfo?.last_name || ''
                    }
                  });
                  callGtagEvent(GOOGLE_CONVERSION_ID, GOOGLE_CONVERSION_TAG_STEP_2);
                  callLinkedinTagEvent(LINKEDIN_CONVERSION_TAG_STEP_2);
                  // eslint-disable-next-line no-empty
                } catch (err) {}
              }}
              size="large">
              <FormattedMessage id="confirm_name" defaultMessage="Choose this name" />
            </RegistrationButton>
          ) : (
            <Button
              type="submit"
              size="large"
              variant="outlined"
              disabled={!(company.company_name && company.company_name !== '') || isLoading}
              className="sm-w-full"
              sx={{
                minWidth: '240px',
                maxHeight: '48px',
                borderRadius: '31px',
                border: 'solid #253292 2px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: '#253292',
                textTransform: 'none',
                fontFamily: 'TEEE',
                fontWeight: 500
              }}>
              <FormattedMessage id="check_name" defaultMessage="Check name" />
              {isLoading ? <CircularProgress size={18} /> : <ArrowForward />}
            </Button>
          )}
        </div>
      </Box>
    </Box>
  );
}

export default withErrorBoundary(CompanyNameForm, {
  fallback: <div>Failed to load company info form</div>
});
