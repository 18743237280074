import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Radio } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { withErrorBoundary } from '@sentry/react';
import { CompanyState, PaymentInfoState } from '../types';
import RegistrationButton from '../../../common/components/RegistrationButton';

type CompanyNameFormProps = {
  company: CompanyState;
  setCompany: Function;
  paymentInfo: PaymentInfoState;
  setPaymentInfo: Function;
  setStep: Function;
};

const PackageBenefits: { [key: string]: { type: string; text: string }[] } = {
  virtual: [
    {
      type: 'checked_green',
      text: 'virtual_benefits_1'
    },
    {
      type: 'checked_green',
      text: 'virtual_benefits_2'
    },
    {
      type: 'checked_green',
      text: 'virtual_benefits_3'
    },
    {
      type: 'checked_green',
      text: 'virtual_benefits_4'
    },
    {
      type: 'checked_green',
      text: 'virtual_benefits_5'
    },
    {
      type: 'danger',
      text: 'virtual_benefits_6'
    }
  ],
  remote: [
    {
      type: 'checked_green',
      text: 'remote_benefits_1'
    },
    {
      type: 'checked_green',
      text: 'remote_benefits_2'
    },
    {
      type: 'checked_green',
      text: 'remote_benefits_3'
    },
    {
      type: 'checked_green',
      text: 'remote_benefits_4'
    },
    {
      type: 'checked_green',
      text: 'remote_benefits_5'
    }
  ],
  crypto: [
    {
      type: 'checked_green',
      text: 'crypto_benefits_1'
    },
    {
      type: 'checked_green',
      text: 'crypto_benefits_2'
    },
    {
      type: 'checked_green',
      text: 'crypto_benefits_3'
    },
    {
      type: 'checked_green',
      text: 'crypto_benefits_4'
    },
    {
      type: 'checked_green',
      text: 'crypto_benefits_5'
    }
  ]
};

const CompanyTypes: { name: string; price: string }[] = [
  {
    name: 'remote',
    price: '79'
  },
  {
    name: 'crypto',
    price: '149'
  },
  {
    name: 'virtual',
    price: '29'
  }
];

function CompanyNameForm({
  company,
  setCompany,
  setStep,
  paymentInfo,
  setPaymentInfo
}: CompanyNameFormProps) {
  return (
    <Box className="w-full flex flex-col items-center md:items-start" style={{ maxWidth: '700px' }}>
      <p className="registration-text-large-blue text-center md:text-left mt-14">
        <FormattedMessage id="choose_your_plan" defaultMessage="Choose your plan" />
      </p>
      <Box component="form" className="w-full mt-5" sx={{ mt: 1 }}>
        {CompanyTypes.map((type, i) => (
          <TypeOption
            key={i}
            type={type.name}
            price={type.price}
            isChecked={company.company_type === type.name}
            company={company}
            setCompany={setCompany}
            paymentInfo={paymentInfo}
            setPaymentInfo={setPaymentInfo}
          />
        ))}

        <RegistrationButton
          type="submit"
          size="large"
          variant="contained"
          disabled={!company.company_type}
          onClick={() => setStep(1)}
          className="sm-w-full">
          <FormattedMessage id="continue" defaultMessage="Continue" />
          <ArrowForward />
        </RegistrationButton>
      </Box>
    </Box>
  );
}

function TypeOption({
  type,
  price,
  isChecked,
  company,
  setCompany,
  paymentInfo,
  setPaymentInfo
}: {
  type: string;
  price: string;
  isChecked: boolean;
  company: CompanyState;
  setCompany: Function;
  paymentInfo: PaymentInfoState;
  setPaymentInfo: Function;
}) {
  const intl = useIntl();

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="w-full flex items-start mb-5 cursor-pointer"
      id={type}
      onClick={(e: React.SyntheticEvent) => {
        setCompany({ ...company, company_type: (e.target as HTMLInputElement).id });

        setPaymentInfo({
          ...paymentInfo,
          accountancy: (e.target as HTMLInputElement).id === 'virtual'
        });
      }}
      style={{
        border: '2px solid',
        borderRadius: '10px',
        // eslint-disable-next-line react/no-this-in-sfc
        borderColor: isChecked ? '#253292' : '#DFE0EB',
        padding: '35px'
      }}>
      <Radio
        id={`company_type_checkbox_virtual${type}`}
        data-testid="company_type_checkbox"
        name="company_type_checkbox"
        className="pointer-events-none"
        checked={isChecked}
        sx={{
          paddingRight: 0,
          paddingLeft: 0,
          color: '#DFE0EB',
          '&.Mui-checked': {
            color: '#253292'
          }
        }}
      />
      <img
        src={`/assets/images/icon-${type}.svg`}
        alt={`${type} icon`}
        width={50}
        className="pointer-events-none hidden lg:block mx-4"
      />
      <div className="flex flex-col flex-1 pointer-events-none text-left ml-4 lg:ml-0">
        <div className="flex  justify-between items-center flex-1">
          <h1 className="text-xl uppercase font-semibold" style={{ color: '#253292' }}>
            <FormattedMessage id={`${type}_company_type`} defaultMessage={`${type} company`} />
          </h1>

          <img
            src="/assets/images/long-arrow.png"
            alt="arrow icon"
            width={60}
            style={{ maxHeight: '15px' }}
            className="pointer-events-none hidden lg:block mx-4"
          />

          <span className="text-xl font-bold overflow-visible" style={{ color: '#253292' }}>
            €{price}/mo
          </span>
        </div>

        <p className="my-5 registration-text-medium-gray " style={{ textAlign: 'left' }}>
          <FormattedMessage
            id={`${type}_company_type_subtitle`}
            defaultMessage="Choose your prefer option"
          />
        </p>

        {isChecked
          ? PackageBenefits[type].map((item, i: number) => {
              const translated = intl.formatMessage({ id: item.text, defaultMessage: 'Option' });
              return (
                <div className="flex items-center mt-4" key={i}>
                  <img src={`/assets/icons/${item.type}.svg`} alt={item.type} className="mr-4" />
                  {/* eslint-disable-next-line react/no-danger */}
                  <p dangerouslySetInnerHTML={{ __html: translated }} />
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default withErrorBoundary(CompanyNameForm, {
  fallback: <div>Failed to load company info form</div>
});
