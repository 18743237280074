export type EnvironmentType = 'development' | 'staging' | 'production';
export const ENVIRONMENT_DEVELOPMENT: EnvironmentType = 'development';

// Payment statuses
export const PAYMENT_STATUS_PROCESSING = 'processing';
export const PAYMENT_STATUS_EXPIRED = 'expired';
export const PAYMENT_STATUS_CANCELED = 'canceled';
export const PAYMENT_STATUS_FAILED = 'failed';
export const PAYMENT_STATUS_PAID = 'paid';
export const PAYMENT_STATUS_SUCCEEDED = 'succeeded';
export const PAYMENT_STATUS_REQUIRES_ACTION = 'requires_action';
export const PAYMENT_STATUS_REQUIRES_PAYMENT_METHOD = 'requires_payment_method';

// URL & Routes
export const FRONT_END_FALLBACK_URL = 'https://app.companio.co';

// Auth
export const AUTH_TOKEN_KEY = 'registration_auth_token';

// Google Ads
export const GOOGLE_CONVERSION_TAG_STEP_1 = 'Y9eBCL2py7IDEIKbytoB';
export const GOOGLE_CONVERSION_TAG_STEP_2 = 'aNl2CL-dzLIDEIKbytoB';
export const GOOGLE_CONVERSION_TAG_STEP_3 = 'lVBGCLqGlbIDEIKbytoB';
export const GOOGLE_CONVERSION_TAG_STEP_4 = 'LiQdCKfgzbIDEIKbytoB';
export const GOOGLE_CONVERSION_ID = 'AW-458395010';
export const GOOGLE_CONVERSION_ACTION_PURCHASE = 'purchase';

// LinkedIn Ads
export const LINKEDIN_CONVERSION_TAG_STEP_1 = 15326818;
export const LINKEDIN_CONVERSION_TAG_STEP_2 = 15326826;
export const LINKEDIN_CONVERSION_TAG_STEP_3 = 15326834;
export const LINKEDIN_CONVERSION_TAG_STEP_4 = 15326842;

// Endpoints
export const API_ENDPOINT_REGISTRATIONS = '/registrations';
export const API_ENDPOINT_PAYMENTS = '/payments';
export const API_ENDPOINT_VERIFY = '/verify';
export const API_ENDPOINT_SETUP_INTENT = '/setup_intent';
export const API_ENDPOINT_RESTART = '/restart';
export const API_ENDPOINT_THANK_YOU = '/thank_you';
export const API_ENDPOINT_STATUS = '/status';

// Stripe
export const STRIPE_INTENT_PAYMENT_PREFIX = 'seti';
