import { withErrorBoundary } from '@sentry/react';
import { Box, Container, CircularProgress, MenuItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { IntlShape } from '@formatjs/intl/src/types';
import ReCAPTCHA from 'react-google-recaptcha';
import { RegDataState, Localization } from './types';
import switchingReason from '../../common/utils/switchingReason';
import previousProviders from '../../common/utils/previousProviders';
import useCrispChat from '../../common/utils/chispChatHook';
import RegistrationTextInput from '../../common/components/RegistrationTextInput';
import RegistrationButton from '../../common/components/RegistrationButton';
import { isValidEmail, isValidString, toBooleanOrFalse } from '../../common/utils/validations';
import RegistrationCheckbox from '../../common/components/RegistrationCheckbox';
import RegistrationSelect from '../../common/components/RegistrationSelect';
import { callGtagEvent, setGtagVariables } from '../../common/utils/handleGtag';
import {
  ENVIRONMENT_DEVELOPMENT,
  GOOGLE_CONVERSION_ID,
  GOOGLE_CONVERSION_TAG_STEP_1,
  LINKEDIN_CONVERSION_TAG_STEP_1
} from '../../common/utils/constants';
import callLinkedinTagEvent from '../../common/utils/handleLinkedinTag';
import CallAppointmentButton from '../../common/components/CallAppointmentButton';

const { REACT_APP_ENVIRONMENT: ENVIRONMENT } = process.env;

export const submitFunction = async (
  e: React.SyntheticEvent,
  regData: RegDataState,
  setCurrentStep: Function,
  setIsFetching: Function,
  ref: Object | null,
  type: string,
  locale: string,
  companyType: string,
  captchaRef: any,
  setErrorMessage: Function,
  setIsRecaptchaVerified: Function
) => {
  setIsFetching(true);
  e.preventDefault();

  let recaptchaToken = null;
  if (ENVIRONMENT !== ENVIRONMENT_DEVELOPMENT) {
    recaptchaToken = captchaRef.current.getValue();
  }

  if (companyType) {
    // eslint-disable-next-line no-param-reassign
    regData.company_type = companyType;
  }

  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/token`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...regData,
      ...ref,
      type,
      locale,
      token_recaptcha: recaptchaToken
    })
  });
  const data = await response.json();

  if (ENVIRONMENT !== ENVIRONMENT_DEVELOPMENT) {
    captchaRef.current.reset();
    setIsRecaptchaVerified(false);
  }

  if (response.status === 200) {
    setGtagVariables({
      email: regData.email,
      address: {
        first_name: regData.first_name,
        last_name: regData.last_name
      }
    });
    callGtagEvent(GOOGLE_CONVERSION_ID, GOOGLE_CONVERSION_TAG_STEP_1);
    callLinkedinTagEvent(LINKEDIN_CONVERSION_TAG_STEP_1);
    setCurrentStep(1);
    setIsFetching(false);
    return null;
  }
  setIsFetching(false);
  return setErrorMessage(data.message);
};

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const getPromoCodes = (queryParams: URLSearchParams) => {
  let affiliateCode = queryParams.get('code');
  try {
    if (isValidString(affiliateCode)) {
      // @ts-ignore
      affiliateCode = atob(decodeURIComponent(affiliateCode));
    }
  } catch (e) {
    affiliateCode = null;
  }

  const referralCode = queryParams.get('referrer');
  const promoCode = queryParams.get('promo_code');

  return {
    ...(isValidString(affiliateCode) ? { affiliate_code: affiliateCode } : null),
    ...(isValidString(referralCode) ? { referral_code: referralCode } : null),
    ...(isValidString(promoCode) ? { promo_code: promoCode?.toUpperCase() } : null)
  };
};

export const getLocale = (queryParams: URLSearchParams): string => {
  const locale = queryParams.get('locale');
  if (locale && isValidString(locale)) {
    return locale.split('_')[0];
  }
  return (navigator.language || 'en_US').replace('-', '_').split('_')[0];
};

function Register({ setLocale, locale }: Localization) {
  useCrispChat();
  const intl = useIntl();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isSwitch, setIsSwitch] = useState<boolean>(false);
  const [companyType, setCompanyType] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const queryParams = useQueryParams();
  const ref = getPromoCodes(queryParams);
  const paramLocale = getLocale(queryParams);
  const captchaRef = useRef(null);

  useEffect(() => {
    // Localize
    setLocale(paramLocale);

    // Triggers switch or registration UI
    const switching = queryParams.get('switch');
    setIsSwitch(toBooleanOrFalse(switching));

    // Triggers switch or registration UI
    const type = queryParams.get('type');
    if (type) {
      setCompanyType(type);
    }
  }, []);

  const [regData, setRegData] = useState<RegDataState>({
    first_name: '',
    last_name: '',
    email: '',
    checkbox: false,
    switch_reason: '',
    switch_provider: '',
    company_type: ''
  });

  const emailErrorMessage = (email: string) => {
    if (!isValidString(email) || !isValidEmail(email)) {
      return intl.formatMessage({ id: 'invalid_email', defaultMessage: 'Enter a valid email.' });
    }
    return '';
  };

  const handleInputChange = (e: React.SyntheticEvent) => {
    setErrorMessage('');
    if ((e.target as HTMLInputElement).id === 'email') {
      setEmailError(emailErrorMessage((e.target as HTMLInputElement).value));
    }
    setRegData({
      ...regData,
      [(e.target as HTMLInputElement).id
        ? (e.target as HTMLInputElement).id
        : (e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value
    });
  };
  const handleCheckboxChange = () => {
    setErrorMessage('');
    setRegData({
      ...regData,
      checkbox: !regData.checkbox
    });
  };

  const handleNotAllFieldsFullfilled = (): boolean => {
    if (
      !regData.first_name ||
      !regData.last_name ||
      !regData.email ||
      !regData.checkbox ||
      isValidString(emailError) ||
      isValidString(emailError) ||
      isFetching ||
      (ENVIRONMENT !== ENVIRONMENT_DEVELOPMENT && !isRecaptchaVerified)
    ) {
      return true;
    }
    if (isSwitch) {
      return !(regData.switch_provider.length > 0 && regData.switch_reason.length > 0);
    }
    return false;
  };

  const getRightSideImg = () => {
    const allYouNeed = (
      <img
        src="/assets/images/register_allyouneed.svg"
        alt="people"
        className="mx-auto"
        style={{ maxHeight: '500px' }}
      />
    );
    const confirmEmail = (
      <img
        src="/assets/images/illustration-confirm-email.svg"
        alt="people"
        className="mx-auto"
        style={{ width: '70%', maxWidth: '450px' }}
      />
    );
    const switchImg = (
      <img
        src="/assets/images/switching_people.svg"
        alt="people"
        className="mx-auto"
        style={{ maxHeight: '422px' }}
      />
    );

    if (currentStep === 0) {
      if (isSwitch) {
        return switchImg;
      }
      return allYouNeed;
    }
    return confirmEmail;
  };

  return (
    <div
      className="flex flex-col-reverse forceroot md:flex-row min-h-screen index-registration-page"
      data-testid="register_base">
      <div
        className="flex-1 rounded-t-3xl relative md:rounded-t-none bg-white"
        style={{ padding: '40px 30px 40px 30px' }}>
        <Container
          maxWidth="sm"
          className="flex-col justify-between min-h-full"
          sx={{ display: 'flex!important' }}>
          <a
            target="_self"
            rel="noreferrer"
            href={locale === 'es' ? 'https://companio.co/es/' : 'https://companio.co/'}>
            <img
              src="/assets/images/companio-logo.png"
              className="hidden md:block"
              width="123"
              alt="logo"
              style={{ maxHeight: '30px' }}
            />
          </a>
          {currentStep === 0 ? (
            <RegisterForm
              regData={regData}
              handleInputChange={handleInputChange}
              handleCheckboxChange={handleCheckboxChange}
              onSubmit={(e: React.SyntheticEvent, type: string) =>
                submitFunction(
                  e,
                  regData,
                  setCurrentStep,
                  setIsFetching,
                  ref,
                  type,
                  paramLocale,
                  companyType,
                  captchaRef,
                  setErrorMessage,
                  setIsRecaptchaVerified
                )
              }
              isFetching={isFetching}
              handleNotAllFieldsFullfilled={handleNotAllFieldsFullfilled}
              emailError={emailError}
              intl={intl as IntlShape}
              isSwitch={isSwitch}
              setIsSwitch={setIsSwitch}
              setIsRecaptchaVerified={setIsRecaptchaVerified}
              captchaRef={captchaRef}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          ) : (
            <RegisterSuccess regData={regData} intl={intl as IntlShape} />
          )}

          <div />
          {/* has to be here because of flex */}
        </Container>
      </div>

      <div
        className="flex-1 text-center pb-9 md:pb-0 md:flex flex-col md:flex-row items-center justify-center max-h-60 md:max-h-full overflow-hidden"
        style={{
          background: isSwitch
            ? 'linear-gradient(109.4deg, #018EFF 1.26%, #9A53CB 96.4%)'
            : 'linear-gradient(109.4deg, #FF4EA1 1.26%, #FEA500 96.4%)',
          padding: '40px'
        }}>
        <img
          src="/assets/images/companio-logo.png"
          alt="companio"
          className="md:hidden brightness-0 invert mx-auto mb-4"
          style={{ maxHeight: '40px' }}
        />
        {getRightSideImg()}
      </div>
      <CallAppointmentButton locale={locale} setLocale={setLocale} />
    </div>
  );
}

export function RegisterForm({
  regData,
  handleInputChange,
  handleCheckboxChange,
  onSubmit,
  isFetching,
  emailError,
  intl,
  isSwitch,
  setIsSwitch,
  handleNotAllFieldsFullfilled,
  setIsRecaptchaVerified,
  captchaRef,
  errorMessage,
  setErrorMessage
}: {
  regData: RegDataState;
  handleInputChange: Function;
  handleCheckboxChange: Function;
  onSubmit: Function;
  isFetching: boolean;
  emailError: string;
  intl: IntlShape;
  isSwitch: boolean;
  setIsSwitch: Function;
  handleNotAllFieldsFullfilled: Function;
  setIsRecaptchaVerified: Function;
  captchaRef: any;
  errorMessage: string;
  setErrorMessage: Function;
}) {
  return (
    <div>
      <h1 className="text-3xl font-extrabold text-left" style={{ color: '#253292' }}>
        <FormattedMessage
          id={isSwitch ? 'switch_step_1_title' : 'sign_up_with_companio'}
          defaultMessage={isSwitch ? 'Come and join us!' : 'Sign up with Companio'}
        />
      </h1>
      <p className="mt-5 mb-5 text-gray-600 text-left">
        <FormattedMessage
          id={isSwitch ? 'switch_step_1_subtitle' : 'open_your_european_business'}
          defaultMessage={
            isSwitch
              ? 'Join us today and enjoy all the benefits of Companio.'
              : 'Open your European business today. Break on through to the digital side, and say goodbye to limits!'
          }
        />
      </p>

      <Box
        component="form"
        onSubmit={(e: React.SyntheticEvent) => onSubmit(e, isSwitch ? 'switching' : 'registration')}
        noValidate
        sx={{ mt: 1 }}
        data-testid="form">
        <div className="flex flex-col md:flex-row">
          <RegistrationTextInput
            className="md:mr-3 w-full md:w-1/2 mt-5"
            placeholder="John"
            id="first_name"
            data-testid="first_name"
            label={intl.formatMessage({ id: 'name', defaultMessage: 'Name' })}
            value={regData.first_name}
            onChange={(e: React.SyntheticEvent) => handleInputChange(e)}
            required
          />
          <RegistrationTextInput
            className="md:ml-3 w-full md:w-1/2 mt-5"
            placeholder="Smith"
            id="last_name"
            data-testid="last_name"
            label={intl.formatMessage({ id: 'last_name', defaultMessage: 'Last name' })}
            value={regData.last_name}
            onChange={(e: React.SyntheticEvent) => handleInputChange(e)}
            required
          />
        </div>
        <RegistrationTextInput
          className="w-full mt-5"
          placeholder="yourcompany@email.com"
          label={intl.formatMessage({ id: 'email', defaultMessage: 'Email' })}
          id="email"
          value={regData.email}
          data-testid="email"
          onChange={(e: React.SyntheticEvent) => handleInputChange(e)}
          error={isValidString(emailError)}
          helperText={isValidString(emailError) ? emailError : ''}
          required
        />
        {/* Only for switching */}
        {isSwitch && (
          /* Previous provider */
          <div className="flex flex-col md:flex-row ">
            <div className="w-full md:w-1/2">
              <RegistrationSelect
                className="md:pr-3 w-full mt-5"
                label={intl.formatMessage({
                  id: 'previous_provider',
                  defaultMessage: 'Previous Provider'
                })}
                id="switch_provider"
                required
                value={regData.switch_provider}
                onChange={(e: React.SyntheticEvent) => handleInputChange(e)}>
                {previousProviders.map((provider) => (
                  <MenuItem key={provider.id} id={provider.value} value={provider.value}>
                    <div className="flex items-center">
                      <RegistrationCheckbox
                        id={provider.value}
                        name="provider.value"
                        onChange={() => {}}
                        checked={regData.switch_provider === provider.value}
                      />
                      {provider.id}
                    </div>
                  </MenuItem>
                ))}
              </RegistrationSelect>
            </div>
            {/* Why choose company? */}
            <div className="w-full md:w-1/2">
              <RegistrationSelect
                className="md:pl-3 w-full mt-5"
                label={intl.formatMessage({
                  id: 'why_did_you_choose_us',
                  defaultMessage: 'Why did you choose us?'
                })}
                required
                id="switch_reason"
                value={regData.switch_reason}
                onChange={(e: React.SyntheticEvent) => handleInputChange(e)}>
                {switchingReason.map((reason) => (
                  <MenuItem key={reason.id} id={reason.value} value={reason.value}>
                    <div className="flex items-center justify-start">
                      <RegistrationCheckbox
                        className="w-auto"
                        id={reason.value}
                        name={reason.value}
                        onChange={() => {}}
                        checked={regData.switch_reason === reason.value}
                      />
                      {intl.formatMessage({ id: reason.id })}
                    </div>
                  </MenuItem>
                ))}
              </RegistrationSelect>
            </div>
          </div>
        )}
        {/* Only for switching */}
        <div className="mt-5">
          <RegistrationCheckbox
            id="checkbox"
            data-testid="checkbox"
            name="checkbox"
            checked={regData.checkbox}
            onChange={(e: React.SyntheticEvent) => handleCheckboxChange(e)}
            label={
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#9FA2B4',
                  fontFamily: 'TEEE'
                }}>
                <FormattedMessage id="i_accept" defaultMessage="I accept the" />{' '}
                <a target="_blank" rel="noreferrer" href="https://companio.co/terms/">
                  <FormattedMessage
                    id="terms_and_conditions"
                    defaultMessage="Terms and conditions"
                  />
                </a>{' '}
                &{' '}
                <a target="_blank" rel="noreferrer" href="https://companio.co/privacy-policy/">
                  <FormattedMessage id="privacy_policy" defaultMessage="Privacy Policy" />
                </a>
                <strong style={{ color: '#FF4EA1' }}> * </strong>
              </p>
            }
          />
        </div>
        {ENVIRONMENT !== ENVIRONMENT_DEVELOPMENT && (
          <ReCAPTCHA
            onChange={(value) => setIsRecaptchaVerified(!!value)}
            className="mt-5"
            sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_REACT_APP_SITE_KEY}`}
            ref={captchaRef}
          />
        )}
        <div className="mt-5 md:w-[255px] w-full">
          <RegistrationButton
            disabled={handleNotAllFieldsFullfilled()}
            className="w-full "
            type="submit"
            variant="filled"
            size="large">
            {isFetching ? (
              <CircularProgress size={18} />
            ) : (
              <FormattedMessage id="lets_go" defaultMessage="Let's go!" />
            )}
          </RegistrationButton>
        </div>
        <p
          className="mt-5 text-left"
          style={{ color: '#9FA2B4', fontWeight: 400, fontSize: '14px' }}>
          <FormattedMessage
            id={isSwitch ? 'do_not_registered' : 'already_account'}
            defaultMessage={
              isSwitch ? "Don't have a company yet?" : 'Do you have a company already?'
            }
          />{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="hover:underline"
            style={{
              color: '#253292',
              fontWeight: 500
            }}
            href="#"
            onClick={() => {
              setErrorMessage('');
              setIsSwitch((prev: boolean) => !prev);
            }}>
            <FormattedMessage
              id={isSwitch ? 'register_with_us' : 'switch_to_us'}
              defaultMessage={isSwitch ? 'Register with us!' : 'Switch to us!'}
            />
          </a>
        </p>
        {isValidString(errorMessage) && (
          <div
            className="mt-5 text-left text-[red] text-[14px]"
            dangerouslySetInnerHTML={{ __html: errorMessage }}
          />
        )}
      </Box>
    </div>
  );
}

export function RegisterSuccess({ regData, intl }: { regData: RegDataState; intl: IntlShape }) {
  return (
    <div>
      <p className="text-3xl font-extrabold text-center" style={{ color: '#253292' }}>
        <FormattedMessage id="check_your_email" defaultMessage="Check your email!" />
      </p>
      <p className="mt-5 text-gray-600 text-center" data-testid="success_email">
        <FormattedMessage
          id="temporary_verification"
          defaultMessage="We have sent you a temporary verification link to"
        />{' '}
        {regData.email ||
          intl.formatMessage({ id: 'your_email', defaultMessage: 'your email address' })}
        {'. '}
        <FormattedMessage
          id="remember_check_spam"
          defaultMessage="Remember to check your spam folder. If you still can’t find it, contact us via chat."
        />
      </p>
    </div>
  );
}

export default withErrorBoundary(Register, {
  fallback: <div>Failed to load</div>
});
