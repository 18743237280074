import React from 'react';
import { withErrorBoundary } from '@sentry/react';

function LoadingSpinner({
  size,
  color,
  className
}: {
  size: number;
  color: string;
  className: string;
}) {
  return (
    <div className={`${className} p-1`}>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        color={color}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="spinner-secondHalf">
            <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
          <linearGradient id="spinner-firstHalf">
            <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
        </defs>
        <g strokeWidth={size / 25}>
          <path
            stroke="url(#spinner-secondHalf)"
            d={`M ${size / 50} ${size / 2} A ${size / (200 / 96)} ${size / (200 / 96)} 0 0 1 ${
              size / (200 / 196)
            } ${size / 2}`}
          />
          <path
            stroke="url(#spinner-firstHalf)"
            d={`M ${size / (200 / 196)} ${size / 2} A ${size / (200 / 96)} ${
              size / (200 / 96)
            } 0 0 1 ${size / 50} ${size / 2}`}
          />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            d={`M ${size / 50} ${size / 2} A ${size / (200 / 96)} ${size / (200 / 96)} 0 0 1 ${
              size / 50
            } ${size / (200 / 98)}`}
          />
        </g>
        <animateTransform
          from="0 0 0"
          to="360 0 0"
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1300ms"
        />
      </svg>
    </div>
  );
}

export default withErrorBoundary(LoadingSpinner, {
  fallback: <div>Failed to load spinner component</div>
});
