import { GOOGLE_CONVERSION_ACTION_PURCHASE } from './constants';
import { isValidString } from './validations';

export type GtagVariables = {
  email?: string;
  phone_number?: string;
  address?: {
    first_name?: string;
    last_name?: string;
    street?: string;
    city?: string;
    region?: string;
    postal_code?: string;
    country?: string;
  };
};

export const setGtagVariables = (variables: GtagVariables) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') return;
  // @ts-ignore
  window.gtag('set', 'user_data', variables);
};

export const callGtagEvent = (conversionId: string, conversionLabel: string, type?: 'purchase') => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') return;
  // @ts-ignore
  window.gtag('event', 'conversion', {
    send_to: `${conversionId}/${conversionLabel}`,
    ...(isValidString(type) && type?.toLowerCase() === GOOGLE_CONVERSION_ACTION_PURCHASE
      ? { transaction_id: '' }
      : null)
  });
};
