type Providers = {
  id: string;
  value: string;
};

const previousProviders: Providers[] = [
  { id: 'Xolo', value: 'xolo' },
  { id: '1Office', value: '1Office' },
  { id: 'erHub', value: 'erhub' },
  { id: 'Comistar', value: 'comistar' },
  { id: 'Unicount', value: 'unicount' },
  { id: 'Other', value: 'other' }
];

export default previousProviders;
