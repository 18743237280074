import { withErrorBoundary } from '@sentry/react';
import React from 'react';
import { Localization } from '../../features/main/types';

const urlES =
  'https://calendar.google.com/calendar/appointments/schedules/AcZssZ1O7jsioZzBlB2XUL9R77aYeiA0fWIUKf3twPsylOCrNYqpVL1K7wbnE3FUAzc2dM4hXbTX33lm?gv=true';
const urlEN =
  'https://calendar.google.com/calendar/appointments/schedules/AcZssZ1uIIQr6TBG95RXOvYxe8v39CSq4nFUfNAKsBBGFIQhfOBnbpBkgj8HzkGqdy4Pby2R9Y-sG8ET?gv=true';

function CallAppointmentButton({ locale }: Localization) {
  return (
    <a
      href={locale === 'es' ? urlES : urlEN}
      target="_blank"
      rel="noreferrer"
      className="absolute w-54 bottom-3.5 right-20 xs:w-60 xs:bottom-5 xs:right-24 bg-white rounded-full aspect-square p-3 cursor-pointer"
      aria-label="Set up a call">
      <img src="/assets/icons/phone_call.svg" alt="Set up a call" />
    </a>
  );
}

export default withErrorBoundary(CallAppointmentButton, {
  fallback: <div>Failed to load Call Appointment Button</div>
});
