import React, { ReactNode, useState } from 'react';
// import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, Modal } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import { ArrowForward } from '@mui/icons-material';
import toast from 'react-hot-toast';
import RegistrationTextInput from '../../../common/components/RegistrationTextInput';
import { CompanyState } from '../types';
import { isValidString } from '../../../common/utils/validations';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { emptyMember } from './utils/constants';
import RegistrationButton from '../../../common/components/RegistrationButton';

type CompanyCheckRegistryProps = {
  company: CompanyState;
  companyErrors: CompanyState | null;
  handleCompanyInputChange: Function;
  setCompany: Function;
  setMembers: Function;
  setStep: Function;
  locale: string;
};

function CompanyCheckRegistry({
  company,
  companyErrors,
  handleCompanyInputChange,
  setCompany,
  setMembers,
  setStep,
  locale
}: CompanyCheckRegistryProps) {
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyNotFound, setCompanyNotFound] = useState<boolean>(false);
  const [stepText, setStepText] = useState<{ title: ReactNode; subtitle: ReactNode }>({
    title: intl.formatMessage({ id: 'registry_code_title' }),
    subtitle: intl.formatMessage(
      { id: 'registry_code_subtitle' },
      {
        link: (
          <a
            className="hover:underline text-[#0000ee]"
            href="https://ariregister.rik.ee/eng"
            target="_blank"
            rel="noreferrer">
            {intl.formatMessage({ id: 'here' })}
          </a>
        )
      }
    )
  });

  const searchCompanyByRegistryCode = async (e: React.SyntheticEvent) => {
    setIsLoading(true);

    e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/misc/company_details/?registry_number=${company.registry_number}&locale=${locale}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );

    setIsLoading(false);
    const data = await response.json();
    if (data.error || !Object.prototype.hasOwnProperty.call(data, 'company')) {
      setCompanyNotFound(true);
      if (response.status === 409) {
        setStepText({
          title: intl.formatMessage({ id: 'company_already_registered_with_companio_error_title' }),
          subtitle: intl.formatMessage({
            id: 'company_already_registered_with_companio_error_subtitle'
          })
        });
      }
      if (response.status === 400) {
        setStepText({
          title: intl.formatMessage({ id: 'oops_error' }),
          subtitle: intl.formatMessage({ id: 'error_searching_company' })
        });
        toast.error(data.message);
      }
      if (response.status === 404) {
        setStepText({
          title: intl.formatMessage({ id: 'registry_code_title_error' }),
          subtitle: (
            <>
              {' '}
              {intl.formatMessage({ id: 'registry_code_subtitle_error' })}{' '}
              <a
                className="text-[#253292] font-medium hover:underline"
                href="https://ariregister.rik.ee/eng"
                target="_blank"
                rel="noreferrer">
                {' '}
                e-Business Registry.{' '}
              </a>{' '}
              😉
            </>
          )
        });
      }
      setCompany({
        ...company,
        ...{ registry_number: '' }
      });
      return;
    }

    // Only allows companies registered
    if (
      Object.prototype.hasOwnProperty.call(data.company, 'status') &&
      Object.prototype.hasOwnProperty.call(data.company.status, 'short') &&
      (data.company.status.short as string).toUpperCase() !== 'R'
    ) {
      setCompanyNotFound(true);
      setStepText({
        title: intl.formatMessage(
          { id: 'company_invalid_status_error' },
          { status: data.company.status.long }
        ),
        subtitle: intl.formatMessage(
          { id: 'company_invalid_status_error_subtitle' },
          { company: data.company.name, status: data.company.status.long }
        )
      });
      setCompany({
        ...company,
        ...{ registry_number: '' }
      });
      return;
    }

    // Only allow companies legal form OÜ
    if (
      Object.prototype.hasOwnProperty.call(data.company, 'legal_form') &&
      Object.prototype.hasOwnProperty.call(data.company.legal_form, 'short') &&
      (data.company.legal_form.short as string).toUpperCase() !== 'OÜ'
    ) {
      setCompanyNotFound(true);
      setStepText({
        title: intl.formatMessage({ id: 'company_invalid_legal_form_error' }),
        subtitle: intl.formatMessage(
          { id: 'company_invalid_legal_form_subtitle' },
          { company: data.company.name }
        )
      });
      setCompany({
        ...company,
        ...{ registry_number: '' }
      });
      return;
    }
    setCompany((prev: CompanyState) => {
      const c = data.company;
      return {
        ...prev,
        ...{
          is_switch: true,
          company_name: c.name,
          activity_code: c.activity.emtak_code,
          share_capital: c.share_capital.amount,
          activity_description: c.activity.description,
          unassigned_capital: 0,
          vat_number: c.vat_number,
          registry_number: c.registry_number,
          date_registered: c.registered_at,
          email: isValidString(c.email) ? c.email : '',
          phone: isValidString(c.phone) ? c.phone : '',
          website: isValidString(c.website) ? c.website : ''
        }
      };
    });
    setMembers(() => {
      return data.company.members.map((m: any, index: 0, members: any) => {
        return {
          ...emptyMember,
          ...{
            shareholder_contribution: m.shareholder_contribution,
            shareholder: m.type.includes('shareholder'),
            board_member: members.length === 1 ? true : m.type.includes('board_member'),
            first_name: m.first_name,
            last_name: m.last_name,
            email:
              index === 0 && isValidString(data.company.email) ? data.company.email : m.email || '',
            phone: isValidString(m.phone) ? m.phone : '',
            birthdate: m.birthdate,
            eresident_id: m.eresident_id,
            already_e_resident: true,
            ...(index === 0 ? { is_representative: true } : null)
          }
        };
      });
    });
    setStep(2);
  };

  return (
    <Box
      className="w-full md:max-w-max flex flex-col items-center md:items-start"
      style={{ maxWidth: '411px' }}>
      <p className="registration-text-large-blue text-center md:text-left">{stepText.title}</p>
      <p className="mt-5 registration-text-medium-gray">{stepText.subtitle}</p>
      <Box className="w-full mt-5" sx={{ mt: 1 }}>
        <div className="w-full flex items-center mb-5">
          <RegistrationTextInput
            style={{
              maxWidth: '411px',
              width: '100%',
              marginTop: '24px'
            }}
            placeholder={intl.formatMessage({
              id: 'enter_your_company_code',
              defaultMessage: 'Enter your company code'
            })}
            id="registry_number"
            value={company.registry_number || ''}
            onChange={(e: InputEvent) => handleCompanyInputChange(e)}
            error={
              isValidString(companyErrors?.registry_number) ||
              (companyNotFound && company.registry_number === '')
            }
            helperText={companyErrors?.registry_number ? companyErrors.registry_number : ''}
          />
        </div>
        <div className="w-full items-center md:justify-between md:flex md:mt-5 md:mb-5">
          <RegistrationButton
            disabled={isLoading}
            className="invisible md:visible registration-button-w w-full md:mr-3 md:p1 max-w-min"
            onClick={() => setStep(0)}
            id="previous_step_btn"
            variant="outlined"
            size="medium"
            direction="back"
            sx={{ maxWidth: '190px', height: '48px', marginRight: '20px' }}>
            <FormattedMessage id="previous_step" defaultMessage="Previous step" />
          </RegistrationButton>
          <Button
            size="large"
            variant="outlined"
            disabled={
              isValidString(companyErrors?.registry_number) ||
              !isValidString(company.registry_number) ||
              isLoading
            }
            className="sm-w-full"
            onClick={(e: React.SyntheticEvent) => searchCompanyByRegistryCode(e)}
            sx={{
              minWidth: '255px',
              maxHeight: '48px',
              borderRadius: '31px',
              border: 'solid #253292 2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: '#253292',
              textTransform: 'none',
              fontFamily: 'TEEE',
              fontWeight: 500
            }}>
            <FormattedMessage id="check_company" defaultMessage="Check company" />
            <ArrowForward />
          </Button>
        </div>
      </Box>
      <Modal
        open={isLoading}
        sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          style={{
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)'
          }}
          className="bg-white flex-col w-[80%] max-w-[546px] max-h-[343px] p-[30px] md:px-[79px] md:py-[59px] rounded-[12px]">
          <p
            className="text-[16px] font-bold text-[#253292] text-center mt-5"
            style={{ whiteSpace: 'pre-line' }}>
            <FormattedMessage
              id="searching_company_title"
              defaultMessage="We are looking for your company, this will take a few seconds."
            />
          </p>
          <p className="text-[14px] font-normal text-[#4B506D] text-center mt-5">
            <FormattedMessage
              id="searching_company_subtitle"
              defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sed mauris eu ipsum convallis dictum."
            />
          </p>
          <LoadingSpinner color="#4B506D" size={77} className="mt-5 w-full flex justify-center" />
        </div>
      </Modal>
    </Box>
  );
}

export default withErrorBoundary(CompanyCheckRegistry, {
  fallback: <div>Failed to load company info form</div>
});
