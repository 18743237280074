import React from 'react';
import { Slider } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';

export const CustomSlider = withStyles({
  root: {
    '& .MuiSlider-root': { background: 'red' },
    '& .MuiSlider-thumb': {
      width: '22px',
      height: '22px',
      outline: '#ffffff solid 4px',
      outlineOffset: '-4px',
      border: 'none',
      '& :hover': {
        background: 'red'
      }
    },
    '& .MuiSlider-rail': {
      color: '#DFE0EB',
      height: '6px'
    },
    '& .MuiSlider-track': {
      height: '6px'
    }
  }
})(Slider);

type RegistrationSliderProps = {
  sliderId: string;
  value: number;
  maxValue: number;
  onChange: Function;
  percentage: number | string;
  disabled: boolean;
  color: string;
};

function RegistrationCapitalContributionSlider({
  sliderId,
  value,
  maxValue,
  onChange,
  percentage,
  disabled,
  color
}: RegistrationSliderProps) {
  const intl = useIntl();

  // @ts-ignore
  return (
    <div>
      <div className="flex justify-between items-center">
        <p
          className="text-start uppercase"
          style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
          {' '}
          {intl.formatMessage({
            id: 'share_capital_contribution',
            defaultMessage: 'Share capital contribution (€)'
          })}{' '}
        </p>
        <p
          className="text-start uppercase"
          style={{ color: '#4B506D', fontWeight: 400, fontSize: '14px' }}>
          {' '}
          {`${value.toFixed(1)}€ (${percentage}%)`}{' '}
        </p>
      </div>
      <CustomSlider
        id={sliderId}
        name={sliderId}
        onChange={(e) => onChange(e)}
        min={0}
        max={maxValue}
        sx={{ width: '100%', color }}
        value={value}
        step={0.1}
        disabled={disabled}
      />
    </div>
  );
}

export default withErrorBoundary(RegistrationCapitalContributionSlider, {
  fallback: <div>Failed to load slider</div>
});
