import { Box, Button, MenuItem, Modal } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import React from 'react';
import { withErrorBoundary } from '@sentry/react';
import { FormattedMessage, useIntl } from 'react-intl';
import RegistrationSelect from '../../../common/components/RegistrationSelect';
import RegistrationTextInput, {
  StyledTextInput
} from '../../../common/components/RegistrationTextInput';
import RegistrationTextArea from '../../../common/components/RegistrationTextArea';
import countriesData from '../../../common/utils/countriesData';
import { CompanyState, NewMemberState } from '../types';
import Member from './Member';
import RegistrationCheckbox from '../../../common/components/RegistrationCheckbox';
import { isValidString } from '../../../common/utils/validations';
import RegistrationSearchSelect from '../../../common/components/RegistrationSearchSelect';
import emtak from '../../../translations/emtak/emtak_summary_en';

type CompanyInfoFormProps = {
  company: CompanyState;
  handleCompanyInputChange: Function;
  companyErrors: CompanyState | null;
  newMember: NewMemberState;
  handleNewMemberInputChange: Function;
  handleNewMemberCheckboxChange: Function;
  handleClearNewMember: Function;
  newMemberErrors: NewMemberState | null;
  handleMemberInputBirthDate: Function;
  members: NewMemberState[];
  membersError: string;
  handleAddUpdateMember: Function;
  handleRemoveMemberByIndex: Function;
  handleShowMemberModal: Function;
  showMemberModal: boolean;
  modalAction: string;
  handleCloseMemberModal: Function;
  handleValidateMemberFields: Function;
  handleAlreadyEResidentCheckboxChange: Function;
  handleIsRepresentativeCheckboxChange: Function;
  locale: string;
  handleUpdateShareCapitalContribution: Function;
  handleAutocompleteChange: Function;
  selectedOption: any;
};

function CompanyInfoForm({
  company,
  handleCompanyInputChange,
  companyErrors,
  newMember,
  handleNewMemberInputChange,
  handleNewMemberCheckboxChange,
  handleClearNewMember,
  newMemberErrors,
  handleMemberInputBirthDate,
  members,
  membersError,
  handleAddUpdateMember,
  handleRemoveMemberByIndex,
  handleShowMemberModal,
  showMemberModal,
  modalAction,
  handleCloseMemberModal,
  handleValidateMemberFields,
  handleAlreadyEResidentCheckboxChange,
  handleIsRepresentativeCheckboxChange,
  locale,
  handleUpdateShareCapitalContribution,
  handleAutocompleteChange,
  selectedOption
}: CompanyInfoFormProps) {
  const now: any = new Date();
  const intl: any = useIntl();
  const activity: any = selectedOption.description.length
    ? emtak.find((c) => {
        return c.description === selectedOption.description;
      })
    : {
        code: 0,
        description: '',
        includes: '',
        allowed: false,
        needs_license: false,
        warning: ''
      };
  return (
    <Box
      className="flex flex-col items-center md:items-start mt-9"
      style={{ maxWidth: '650px', width: '100%' }}>
      <p className="registration-text-large-blue">
        <FormattedMessage id="company_information" defaultMessage="Company information" />
      </p>
      <p className="mt-5 registration-text-medium-gray">
        <FormattedMessage
          id="step_2_subtitle"
          defaultMessage="Good job! Now please describe the activity of the company and its official email and phone number."
        />
      </p>
      <Box component="form" className="w-full mt-5">
        <div className="md:flex justify-between md:mt-8">
          <RegistrationSearchSelect
            className="md:mr-3 w-full md:w-1/2 mt-5 md:mt-0"
            id="activity_code"
            required
            label={intl.formatMessage({
              id: 'field_of_activity',
              defaultMessage: 'Field of activity'
            })}
            value={activity}
            onChange={handleAutocompleteChange}
            options={emtak}
          />
          <RegistrationTextInput
            className="md:ml-3 w-full md:w-1/2 mt-5 md:mt-0"
            label={
              <>
                {intl.formatMessage({
                  id: 'share_capital',
                  defaultMessage: 'SHARE CAPITAL (€)'
                })}{' '}
                (€){' '}
                <a
                  className="text-[#253292] hover:underline"
                  target="_blank"
                  href={
                    locale === 'en'
                      ? 'https://help.companio.co/en/article/what-is-the-share-capital-of-a-company-16l0en/'
                      : 'https://help.companio.co/es/article/que-es-el-capital-social-de-una-empresa-w07bzc/'
                  }
                  rel="noreferrer">
                  {' '}
                  {intl.formatMessage({
                    id: 'what_does_that_mean',
                    defaultMessage: 'What does that mean?'
                  })}{' '}
                </a>
              </>
            }
            placeholder="1"
            id="share_capital"
            value={company.share_capital}
            onChange={handleCompanyInputChange}
            error={!!companyErrors?.share_capital}
            helperText={companyErrors?.share_capital ? companyErrors.share_capital : ''}
            required
          />
        </div>

        <div className="md:flex justify-between">
          <RegistrationTextInput
            className="md:mr-3 w-full mt-5"
            style={{ width: '100%', marginTop: '26px' }}
            label={intl.formatMessage({
              id: 'corporate_phone',
              defaultMessage: 'CORPORATE PHONE'
            })}
            placeholder="+123456790"
            id="phone"
            value={company.phone}
            onChange={handleCompanyInputChange}
            error={!!companyErrors?.phone}
            helperText={companyErrors?.phone ? companyErrors.phone : ''}
            required
          />

          <RegistrationTextInput
            className="md:ml-3 w-full mt-5"
            style={{ width: '100%', marginTop: '26px' }}
            label={intl.formatMessage({
              id: 'corporate_email',
              defaultMessage: 'CORPORATE EMAIL'
            })}
            placeholder="email@waynecompany.com"
            id="email"
            value={company.email}
            onChange={handleCompanyInputChange}
            error={!!companyErrors?.email}
            helperText={companyErrors?.email ? companyErrors.email : ''}
            required
          />
        </div>

        <RegistrationTextArea
          required
          className="mt-5"
          label={intl.formatMessage({
            id: 'give_details_activity',
            defaultMessage: 'Please describe the activity of your business:'
          })}
          placeholder="My business is about..."
          id="activity_description"
          onChange={handleCompanyInputChange}
          value={company.activity_description}
          error={!!companyErrors?.activity_description}
          helperText={companyErrors?.activity_description ? companyErrors.activity_description : ''}
        />
        {members.length ? (
          <div className="mt-5">
            <p
              className="text-start uppercase"
              style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
              {' '}
              <FormattedMessage id="current_members" defaultMessage="Current members" />{' '}
            </p>
            <div>
              {members.map((member: NewMemberState, index: number) => {
                return (
                  <Member
                    key={index}
                    members={members}
                    member={member}
                    index={index}
                    handleRemoveMemberByIndex={handleRemoveMemberByIndex}
                    handleShowMemberModal={handleShowMemberModal}
                    company={company}
                    handleUpdateShareCapitalContribution={handleUpdateShareCapitalContribution}
                  />
                );
              })}
            </div>
            <p
              className="text-start mt-2"
              style={{
                fontWeight: 400,
                color: '#FE2142',
                fontSize: 12
              }}>
              {' '}
              {membersError}{' '}
            </p>
          </div>
        ) : null}

        <div
          id="Add"
          role="button"
          tabIndex={0}
          data-testid="show_member_modal"
          onClick={(e) => {
            handleShowMemberModal(e, null);
          }}
          className="flex w-max hover:opacity-70"
          style={{ marginTop: '26px' }}>
          <button
            id="Add"
            type="button"
            tabIndex={0}
            onClick={(e) => {
              handleShowMemberModal(e, null);
            }}
            style={{
              background: '#F0F1F7',
              width: 24,
              height: 24,
              borderRadius: 8,
              fontSize: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#9FA2B4',
              overflowY: 'hidden'
            }}>
            +
          </button>
          <p
            id="Add"
            onClick={(e) => {
              handleShowMemberModal(e, null);
            }}
            className="ml-3 mb-3 text-gray-500">
            <FormattedMessage id="add_member" defaultMessage="Add member" />
          </p>
        </div>

        {members.length === 0 && isValidString(membersError) && (
          <p
            className="text-start mt-2"
            style={{
              fontWeight: 400,
              color: '#FE2142',
              fontSize: 12
            }}>
            {' '}
            {membersError}{' '}
          </p>
        )}

        <Modal
          open={showMemberModal}
          onClose={() => {
            handleClearNewMember();
            handleCloseMemberModal();
          }}
          sx={{
            height: '100%',
            overflow: 'scroll',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Box
            className="h-max mt-5 mb-5"
            component="form"
            sx={{
              width: '80%',
              borderRadius: '12px',
              backgroundColor: 'white'
            }}>
            <div style={{ padding: '32px' }}>
              <p className="registration-text-large-blue">
                {modalAction === 'Add'
                  ? intl.formatMessage({
                      id: 'add_member',
                      defaultMessage: 'Add member'
                    })
                  : intl.formatMessage({ id: 'update_member', defaultMessage: 'Update member' })}
              </p>

              <div className="md:flex justify-between" data-testid="new_member_first_name">
                <RegistrationTextInput
                  className="md:mr-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'first_name',
                    defaultMessage: 'First name'
                  })}
                  placeholder="John"
                  id="first_name"
                  value={newMember.first_name}
                  disabled={!members.length || members.indexOf(newMember) === 0}
                  onChange={handleNewMemberInputChange}
                  error={!!newMemberErrors?.first_name}
                  helperText={newMemberErrors?.first_name ? newMemberErrors.first_name : ''}
                  required
                />

                <RegistrationTextInput
                  className="md:ml-3 w-full mt-5"
                  label="Last name"
                  placeholder="Smith"
                  id="last_name"
                  value={newMember.last_name}
                  disabled={!members.length || members.indexOf(newMember) === 0}
                  onChange={handleNewMemberInputChange}
                  error={!!newMemberErrors?.last_name}
                  helperText={newMemberErrors?.last_name ? newMemberErrors.last_name : ''}
                  required
                />
              </div>
              <div className="md:flex justify-between">
                <RegistrationTextInput
                  className="md:mr-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'email',
                    defaultMessage: 'email'
                  })}
                  placeholder="johnsmith@companio.email"
                  id="email"
                  value={newMember.email}
                  disabled={!members.length || members.indexOf(newMember) === 0}
                  onChange={handleNewMemberInputChange}
                  error={!!newMemberErrors?.email}
                  helperText={newMemberErrors?.email ? newMemberErrors.email : ''}
                  required
                />
                <RegistrationTextInput
                  className="md:ml-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'phone',
                    defaultMessage: 'Phone'
                  })}
                  placeholder="+1234567890"
                  id="phone"
                  value={newMember.phone}
                  onChange={handleNewMemberInputChange}
                  error={!!newMemberErrors?.phone}
                  helperText={newMemberErrors?.phone ? newMemberErrors.phone : ''}
                  required
                />
              </div>
              <div className="md:flex justify-between">
                <RegistrationTextInput
                  className="md:mr-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'address',
                    defaultMessage: 'Address'
                  })}
                  placeholder="123, North St."
                  id="address_street"
                  value={newMember.address_street}
                  onChange={handleNewMemberInputChange}
                  error={!!newMemberErrors?.address_street}
                  helperText={newMemberErrors?.address_street ? newMemberErrors.address_street : ''}
                  required
                />
                <RegistrationTextInput
                  className="md:ml-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'city',
                    defaultMessage: 'City'
                  })}
                  placeholder="London"
                  id="address_city"
                  value={newMember.address_city}
                  onChange={handleNewMemberInputChange}
                  error={!!newMemberErrors?.address_city}
                  helperText={newMemberErrors?.address_city ? newMemberErrors.address_city : ''}
                  required
                />
              </div>
              <div className="md:flex justify-between">
                <RegistrationTextInput
                  className="md:mr-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'postcode',
                    defaultMessage: 'Postal code'
                  })}
                  placeholder="12345"
                  id="address_zip"
                  value={newMember.address_zip}
                  onChange={handleNewMemberInputChange}
                  error={!!newMemberErrors?.address_zip}
                  helperText={newMemberErrors?.address_zip ? newMemberErrors.address_zip : ''}
                  required
                />

                <RegistrationSelect
                  className="md:ml-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'country',
                    defaultMessage: 'Country'
                  })}
                  id="address_country"
                  required
                  value={newMember.address_country}
                  error={!!newMemberErrors?.address_country}
                  helperText={
                    newMemberErrors?.address_country ? newMemberErrors.address_country : ''
                  }
                  onChange={handleNewMemberInputChange}>
                  {countriesData.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      <div className="flex items-center justify-start">
                        <RegistrationCheckbox
                          className="w-auto"
                          id={country.code}
                          name={country.code}
                          onChange={() => {}}
                          checked={newMember.address_country === country.code}
                        />
                        {country.name}
                      </div>
                    </MenuItem>
                  ))}
                </RegistrationSelect>
              </div>
              {/* Check list section */}
              <div className="md:flex justify-between">
                <div className="flex items-center md:mr-3 w-full mt-5">
                  <RegistrationCheckbox
                    id="already_e_resident"
                    name="already_e_resident"
                    checked={newMember.already_e_resident}
                    onChange={handleAlreadyEResidentCheckboxChange}
                    label={
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: '12px',
                          color: '#9FA2B4'
                        }}>
                        <FormattedMessage
                          id="already_e_resident"
                          defaultMessage="Already e-resident?"
                        />
                      </p>
                    }
                  />
                </div>
                <div className="md:mr-3 w-full mt-5">
                  {newMember.already_e_resident ? (
                    <RegistrationTextInput
                      className="md:ml-3 w-full"
                      label={intl.formatMessage({
                        id: 'eresident_id',
                        defaultMessage: 'e-Resident code'
                      })}
                      placeholder="123456789"
                      id="eresident_id"
                      value={newMember.eresident_id}
                      onChange={handleNewMemberInputChange}
                      error={!!newMemberErrors?.eresident_id}
                      helperText={newMemberErrors?.eresident_id ? newMemberErrors.eresident_id : ''}
                      required
                    />
                  ) : (
                    <div className="md:ml-3 w-full">
                      <p
                        className="text-start uppercase"
                        style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
                        {' '}
                        <FormattedMessage id="birthdate" defaultMessage="birthdate" />
                        <strong style={{ color: '#FF4EA1' }}> * </strong>
                      </p>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          disableFuture
                          inputFormat="dd/MM/yyyy"
                          defaultCalendarMonth={new Date(now - 568027908258)}
                          value={newMember.birthdate}
                          maxDate={new Date(now - 568027908258)}
                          onChange={(e) => handleMemberInputBirthDate(e)}
                          renderInput={(params) => {
                            const props = {
                              ...params,
                              ...{
                                size: 'small',
                                sx: {
                                  width: '100%'
                                },
                                id: 'birthdate',
                                name: 'birthdate'
                              }
                            };
                            // @ts-ignore
                            return <StyledTextInput {...props} />;
                          }}
                        />
                      </LocalizationProvider>{' '}
                    </div>
                  )}
                </div>
              </div>
              <div className="md:flex justify-between">
                <div className="flex-col md:mr-3 w-full mt-5">
                  <RegistrationCheckbox
                    id="shareholder"
                    name="shareholder"
                    checked={newMember.shareholder}
                    onChange={handleNewMemberCheckboxChange}
                    label={
                      <p
                        className="ml-1 md:ml-0"
                        style={{
                          fontWeight: 400,
                          fontSize: '12px',
                          color: '#9FA2B4',
                          fontFamily: 'TEEE'
                        }}>
                        <FormattedMessage
                          id="add_as_shareholder"
                          defaultMessage="This person will be a shareholder"
                        />{' '}
                        <a
                          style={{ textDecoration: 'underline' }}
                          target="_blank"
                          rel="noreferrer"
                          href={
                            locale === 'es'
                              ? 'https://help.companio.co/es/article/1rf3ea1/'
                              : 'https://help.companio.co/en/article/whats-the-difference-between-shareholders-and-board-members-yquerm/'
                          }>
                          <FormattedMessage
                            id="what_does_that_mean"
                            defaultMessage="What does that mean?"
                          />
                        </a>
                      </p>
                    }
                  />
                  {newMemberErrors?.shareholder && (
                    <p
                      className="text-start mt-2"
                      style={{
                        fontWeight: 400,
                        color: '#FE2142',
                        fontSize: 12
                      }}>
                      {' '}
                      {newMemberErrors?.shareholder}{' '}
                    </p>
                  )}
                </div>
                <div className="md:ml-3 w-full mt-5">
                  <RegistrationCheckbox
                    id="board_member"
                    data-testid="checkbox"
                    name="board_member"
                    checked={newMember.board_member}
                    onChange={handleNewMemberCheckboxChange}
                    label={
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: '12px',
                          color: '#9FA2B4',
                          fontFamily: 'TEEE'
                        }}>
                        <FormattedMessage
                          id="add_as_board_member"
                          defaultMessage="This person will be a board member"
                        />{' '}
                        <a
                          style={{ textDecoration: 'underline' }}
                          target="_blank"
                          rel="noreferrer"
                          href={`${
                            locale === 'es'
                              ? 'https://help.companio.co/es/article/cual-es-la-diferencia-entre-accionistas-y-miembros-de-la-junta-1rf3ea1/'
                              : 'https://help.companio.co/en/article/whats-the-difference-between-shareholders-and-board-members-yquerm/'
                          }`}>
                          <FormattedMessage
                            id="what_does_that_mean"
                            defaultMessage="What does that mean?"
                          />
                        </a>
                      </p>
                    }
                  />
                </div>
              </div>
              <div className="md:flex justify-between">
                <div className="md:mr-3 w-full mt-5">
                  <RegistrationCheckbox
                    id="is_representative"
                    name="is_representative"
                    checked={newMember.is_representative}
                    onChange={handleIsRepresentativeCheckboxChange}
                    label={
                      <p
                        className="ml-2 md:ml-0"
                        style={{
                          fontWeight: 400,
                          fontSize: '12px',
                          color: '#9FA2B4',
                          fontFamily: 'TEEE'
                        }}>
                        <FormattedMessage
                          id="i_am_the_representative"
                          defaultMessage="This person will act as the representative of the company, the company registration invoice will be issued in his/her name."
                        />
                      </p>
                    }
                  />
                </div>
                <div className="md:ml-3 w-full mt-5">
                  <RegistrationCheckbox
                    id="pep"
                    name="pep"
                    checked={newMember.pep}
                    onChange={handleNewMemberCheckboxChange}
                    label={
                      <p
                        className="ml-0.5 md:ml-0"
                        style={{
                          fontWeight: 400,
                          fontSize: '12px',
                          color: '#9FA2B4',
                          fontFamily: 'TEEE'
                        }}>
                        <FormattedMessage
                          id="politically_exposed_1"
                          defaultMessage="This member is a politically exposed person"
                        />{' '}
                        <a
                          style={{ textDecoration: 'underline' }}
                          target="_blank"
                          rel="noreferrer"
                          href={
                            locale === 'es'
                              ? 'https://help.companio.co/es/article/que-es-una-persona-expuesta-politicamente-vmvtlr/'
                              : 'https://help.companio.co/en/article/whats-a-politically-exposed-person-znxlnb/'
                          }>
                          {' '}
                          <FormattedMessage
                            id="politically_exposed_2"
                            defaultMessage="politically exposed person"
                          />
                        </a>
                      </p>
                    }
                  />
                  {newMemberErrors?.pep && (
                    <p
                      className="text-start mt-2"
                      style={{
                        fontWeight: 400,
                        color: '#FE2142',
                        fontSize: 12
                      }}>
                      {' '}
                      {newMemberErrors.pep}{' '}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <hr />
            <div className="" style={{ padding: '36px' }}>
              <div className="text-right">
                {!newMember.board_member && !newMember.shareholder && (
                  <p
                    className="mb-4 md:text-end text-start"
                    style={{
                      fontWeight: 400,
                      color: '#FE2142',
                      fontSize: 12,
                      marginTop: '0.7rem'
                    }}>
                    {intl.formatMessage({
                      id: 'shareholder_or_board_member_error',
                      defaultMessage:
                        'A member must either be a shareholder, board member, or both. Please choose at least one'
                    })}
                  </p>
                )}
              </div>
              <div className="flex items-center justify-end">
                <Button
                  onClick={() => {
                    handleClearNewMember();
                    handleCloseMemberModal();
                  }}
                  sx={{
                    maxWidth: '95px',
                    borderRadius: '31px',
                    width: '100%',
                    background: 'none',
                    color: '#9FA2B4',
                    fontFamily: 'TEEE',
                    fontSize: '14px',
                    fontWeight: 500,
                    textTransform: 'none',
                    marginRight: '10px',
                    ':hover': {
                      backgroundColor: 'none'
                    }
                  }}>
                  <FormattedMessage id="cancel" defaultMessage="Cancel" />
                </Button>
                <Button
                  disabled={handleValidateMemberFields()}
                  onClick={() => {
                    handleAddUpdateMember();
                    handleCloseMemberModal();
                  }}
                  sx={{
                    maxWidth: '95px',
                    width: '100%',
                    borderRadius: '31px',
                    backgroundColor: '#FF4EA1',
                    fontFamily: 'TEEE',
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: 500,
                    textTransform: 'none',
                    ':hover': {
                      backgroundColor: '#FF4EA1',
                      opacity: 0.7
                    },
                    ':disabled': {
                      color: 'white',
                      opacity: 0.3
                    }
                  }}>
                  {modalAction === 'Add'
                    ? intl.formatMessage({
                        id: 'add',
                        defaultMessage: 'Add'
                      })
                    : intl.formatMessage({ id: 'update', defaultMessage: 'Update' })}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default withErrorBoundary(CompanyInfoForm, {
  fallback: <div>Failed to load company info form</div>
});
