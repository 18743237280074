import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Box, CircularProgress } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import RegistrationButton from '../../../../common/components/RegistrationButton';
import { RegPaymentInfo } from '../../../../app/interfaces';

import { callGtagEvent, setGtagVariables } from '../../../../common/utils/handleGtag';
import {
  API_ENDPOINT_PAYMENTS,
  API_ENDPOINT_REGISTRATIONS,
  API_ENDPOINT_SETUP_INTENT,
  API_ENDPOINT_VERIFY,
  GOOGLE_CONVERSION_ACTION_PURCHASE,
  GOOGLE_CONVERSION_ID,
  GOOGLE_CONVERSION_TAG_STEP_4,
  LINKEDIN_CONVERSION_TAG_STEP_4,
  PAYMENT_STATUS_CANCELED,
  PAYMENT_STATUS_SUCCEEDED,
  STRIPE_INTENT_PAYMENT_PREFIX
} from '../../../../common/utils/constants';
import { readAuthToken } from '../../../../common/utils/helpers';
import callLinkedinTagEvent from '../../../../common/utils/handleLinkedinTag';

const verifySetupIntent = async (
  intentId: string,
  navigate: Function,
  intl: IntlShape,
  setIsRetryingPayment: Function,
  regPaymentInfo: RegPaymentInfo,
  setRegPaymentInfo: Function
) => {
  const { representative } = regPaymentInfo;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${API_ENDPOINT_PAYMENTS}${API_ENDPOINT_REGISTRATIONS}${API_ENDPOINT_SETUP_INTENT}${API_ENDPOINT_VERIFY}`,
      {
        method: 'POST',
        headers: {
          Authorization: readAuthToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );
    const data = await response.json();
    if (data && !data.error && data.registration) {
      if (data.registration.payment.status === PAYMENT_STATUS_SUCCEEDED) {
        try {
          setGtagVariables({
            email: representative.email || '',
            address: {
              first_name: representative.first_name,
              last_name: representative.last_name
            }
          });
          callGtagEvent(
            GOOGLE_CONVERSION_ID,
            GOOGLE_CONVERSION_TAG_STEP_4,
            GOOGLE_CONVERSION_ACTION_PURCHASE
          );
          callLinkedinTagEvent(LINKEDIN_CONVERSION_TAG_STEP_4);
          // eslint-disable-next-line no-empty
        } catch {}
        return navigate(`/thank_you/?is_switch=${data.registration.is_switch}`);
      }
      setIsRetryingPayment(false);
      return setRegPaymentInfo(data.registration);
    }
    throw new Error(`${data.error ? `: ${data.message}` : ''}`);
  } catch (e) {
    setIsRetryingPayment(false);
    return toast.error(
      `${intl.formatMessage({
        id: 'error_verifying_payment',
        defaultMessage: 'Error verifying payment'
      })} ${(e as Error).message}`
    );
  }
};

const verifyPaymentIntent = async (
  intentId: string,
  navigate: Function,
  intl: IntlShape,
  setIsRetryingPayment: Function,
  regPaymentInfo: RegPaymentInfo,
  setRegPaymentInfo: Function
) => {
  try {
    const { representative } = regPaymentInfo;

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/payments/registrations/payment/verify`,
      {
        method: 'POST',
        headers: {
          Authorization: readAuthToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );
    const data = await response.json();
    if (data && !data.error && data.registration) {
      if (data.registration.payment.status === PAYMENT_STATUS_SUCCEEDED) {
        try {
          setGtagVariables({
            email: representative.email || '',
            address: {
              first_name: representative.first_name,
              last_name: representative.last_name
            }
          });
          callGtagEvent(
            GOOGLE_CONVERSION_ID,
            GOOGLE_CONVERSION_TAG_STEP_4,
            GOOGLE_CONVERSION_ACTION_PURCHASE
          );
          callLinkedinTagEvent(LINKEDIN_CONVERSION_TAG_STEP_4);
          // eslint-disable-next-line no-empty
        } catch {}
        return navigate(`/thank_you/?is_switch=${data.registration.is_switch}`);
      }
      setIsRetryingPayment(false);
      return setRegPaymentInfo(data.registration);
    }
    throw new Error(`${data.error ? `: ${data.message}` : ''}`);
  } catch (e) {
    setIsRetryingPayment(false);
    return toast.error(
      `${intl.formatMessage({
        id: 'error_verifying_payment',
        defaultMessage: 'Error verifying payment'
      })} ${(e as Error).message}`
    );
  }
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');
function StripeCheckout({
  regPaymentInfo,
  setRegPaymentInfo,
  setIsRetryingPayment
}: {
  regPaymentInfo: RegPaymentInfo;
  setRegPaymentInfo: Function;
  setIsRetryingPayment: Function;
}) {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: regPaymentInfo.client_secret,
        locale: regPaymentInfo.representative.locale as StripeElementLocale | 'en'
      }}>
      <StripeForm
        regPaymentInfo={regPaymentInfo}
        setRegPaymentInfo={setRegPaymentInfo}
        setIsRetryingPayment={setIsRetryingPayment}
      />
    </Elements>
  );
}

function StripeForm({
  regPaymentInfo,
  setRegPaymentInfo,
  setIsRetryingPayment
}: {
  regPaymentInfo: RegPaymentInfo;
  setRegPaymentInfo: Function;
  setIsRetryingPayment: Function;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const intl = useIntl();
  const [isFetching, setIsFetching] = useState(false);

  const isSetupIntent =
    regPaymentInfo.client_secret.substring(0, 4) === STRIPE_INTENT_PAYMENT_PREFIX;
  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsFetching(true);

    if (!stripe || !elements) {
      return setIsFetching(false);
    }

    const result = isSetupIntent
      ? await stripe.confirmSetup({
          elements,
          redirect: 'if_required'
        })
      : await stripe.confirmPayment({
          elements,
          redirect: 'if_required'
        });

    if (result.error) {
      const status =
        result.error.payment_intent?.status || result.error.setup_intent?.status || undefined;
      if (status && status === PAYMENT_STATUS_CANCELED) {
        setRegPaymentInfo((prev: RegPaymentInfo) => ({
          ...prev,
          payment: {
            ...prev.payment,
            status
          }
        }));
        setIsRetryingPayment(false);
        return setIsFetching(false);
      }
      toast.error(result.error.message || '');
      return setIsFetching(false);
    }

    const secretSplit = regPaymentInfo.client_secret.split('_secret_');
    const setupIntent = secretSplit[0];

    if (isSetupIntent) {
      await verifySetupIntent(
        setupIntent,
        navigate,
        intl,
        setIsRetryingPayment,
        regPaymentInfo,
        setRegPaymentInfo
      );
    } else {
      await verifyPaymentIntent(
        setupIntent,
        navigate,
        intl,
        setIsRetryingPayment,
        regPaymentInfo,
        setRegPaymentInfo
      );
    }

    return setIsFetching(false);
  };

  return (
    <>
      <Box
        className="flex flex-col md:items-start items-center p-0 mt-0"
        style={{ maxWidth: '650px', width: '100%' }}>
        <div className="flex flex-col justify-center mt-5" style={{ width: '100%' }}>
          <p className="registration-text-large-blue mb-4 md:mb-8 text-left">
            <FormattedMessage
              id={regPaymentInfo.is_switch ? 'step_3_switching_title' : 'almost_done'}
              defaultMessage="Almost done! 💪🏻"
            />
          </p>
          <p className="registration-text-medium-gray mt-5 mb-5">
            <FormattedMessage
              id={regPaymentInfo.is_switch ? 'switching_payment_text' : 'registration_payment_text'}
              defaultMessage="Enter your card details to make the payment. Check it all again, and if you want to modify something you can go back. If everything is ok, you can proceed to the payment. 🤑"
            />
          </p>
        </div>
        <Box style={{ width: '100%' }}>
          <PaymentElement />
        </Box>
      </Box>
      <div className="flex justify-center mt-5">
        <RegistrationButton
          disabled={isFetching}
          className="registration-button-w md:p10"
          type="submit"
          variant="filled"
          onClick={handleSubmit}
          size="large">
          {isFetching ? (
            <CircularProgress size={18} />
          ) : (
            intl.formatMessage({ id: 'pay_registration', defaultMessage: 'Submit' })
          )}
        </RegistrationButton>
      </div>
    </>
  );
}

export default withErrorBoundary(StripeCheckout, {
  fallback: <div>Failed to load</div>
});
