import React, { useRef, CSSProperties, useState, useLayoutEffect } from 'react';
import { FormControl, Autocomplete, TextField } from '@mui/material';
import { withStyles } from '@mui/styles'; // Import your custom form control component
import { borderColor } from './RegistrationTextInput';

const CustomFormControl = withStyles({
  root: {
    '& .MuiInputBase-root': {
      width: '100%',
      padding: 0
    },
    '& .MuiInputBase-root:after': {
      border: 'none !important',
      content: 'none !important',
      background: 'transparent !important'
    },
    '& .MuiInputBase-root:before': {
      outline: 'none !important',
      content: 'none !important',
      background: 'transparent !important'
    },
    '& .MuiSelect-select': {
      width: '100%',
      backgroundColor: '#FCFDFE',
      color: '#4B506D',
      border: '1px solid #F0F1F7',
      borderRadius: '8px !important',
      fontFamily: 'TEEE',
      fontWeight: 400,
      padding: '8.5px 14px',
      textAlign: 'left',
      '& div': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '& .registration-checkbox': {
          display: 'none'
        }
      }
    }
  }
})(FormControl);

export const StyledTextInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#F0F1F7'
    },
    '& .MuiInputLabel-root': {
      display: 'none'
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'TEEE',
      fontWeight: 400,
      backgroundColor: '#FCFDFE',
      borderRadius: '8px',
      color: '#4B506D',
      '& fieldset': {
        borderColor: '#F0F1F7'
      },
      '&:hover fieldset': {
        border: 'none'
      },
      '& .Mui-focused fieldset': {
        borderColor: '#F0F1F7'
      }
    }
  }
})(TextField);

function RegistrationSearchSelect({
  id,
  onChange,
  value,
  label,
  style,
  className,
  required,
  error,
  success,
  warning,
  helperText,
  options
}: {
  id: string;
  onChange: any;
  value: any;
  label: string;
  style?: CSSProperties;
  className: string;
  required?: boolean;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
  helperText?: string;
  options: any[];
}) {
  const menuButtonRef = useRef<HTMLInputElement | null>(null);
  const [menuButtonWidth, setMenuButtonWidth] = useState<number | string>('inherit');

  useLayoutEffect(() => {
    if (menuButtonRef.current) {
      setMenuButtonWidth(menuButtonRef.current.offsetWidth);
    }
  });

  const defaultProps = {
    options,
    getOptionLabel: (option: any) => option.description
  };
  return (
    <div
      data-testid="registration_select_wrapper"
      className={`d-flex flex-col items-start justify-center ${className}`}
      style={style}>
      {label && (
        <p
          className="text-start uppercase"
          style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
          {label}
          {required && <strong style={{ color: '#FF4EA1' }}> * </strong>}
        </p>
      )}
      <CustomFormControl variant="standard" fullWidth size="medium">
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <Autocomplete
            {...defaultProps}
            ref={menuButtonRef}
            size="small"
            id={id}
            data-testid="registration_select"
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <StyledTextInput
                {...params}
                className="md:ml-3 w-full md:w-1/2 mt-5 md:mt-0"
                style={{
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: borderColor(error, success, warning),
                  padding: '0px',
                  borderRadius: '8px'
                }}
              />
            )}
            sx={{
              '& .MuiPaper-root': {
                width: menuButtonWidth,
                maxHeight: '250px',
                borderBox: '8px',
                padding: 0,
                '& ul': {
                  '& li': {
                    minHeight: '50px',
                    fontFamily: 'TEEE !important',
                    fontWeight: '500 !important',
                    color: '#253292',
                    fontSize: '14px',
                    whiteSpace: 'initial',
                    ':hover': {
                      backgroundColor: '#DDE2FF'
                    }
                  }
                }
              }
            }}
          />
        </div>
      </CustomFormControl>
      {(error || success || warning) && helperText !== '' && (
        <p
          className="text-start"
          data-testid="registration_text_input_helper_text"
          style={{
            fontWeight: 400,
            color: borderColor(error, success, warning),
            fontSize: 12,
            marginTop: '0.7rem'
          }}>
          {' '}
          {helperText}{' '}
        </p>
      )}
    </div>
  );
}

export default RegistrationSearchSelect;
