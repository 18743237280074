import { NewMemberState } from '../../types';

export const emptyMember: NewMemberState = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  shareholder_contribution: '',
  eresident_id: '',
  birthdate: null,
  already_e_resident: false,
  shareholder: false,
  board_member: false,
  is_representative: false,
  pep: false,
  address_street: '',
  address_city: '',
  address_zip: '',
  address_country: ''
};

export const additional = {};
