type EmtakCode = {
  code: number;
  description: string;
  includes: string;
  allowed: boolean;
  needs_license: boolean;
  warning: string;
};

const emtak: EmtakCode[] = [
  {
    code: 59121,
    description: '3D Animation',
    includes: '3D Animation',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74101,
    description: '3D Graphic Design & Creative design',
    includes: '3D Graphic Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71111,
    description: '3D Modeling & CAD',
    includes: '3D Modeling & CAD',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71111,
    description: '3D Models & Product Design & Creative design',
    includes: '3D Models & Product Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82191,
    description: 'Academic Writing & Translations & Research',
    includes: 'Academic Writing & Translations & Research',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69202,
    description: 'Accounting & Finance',
    includes: 'Accounting & Finance',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Ad Design & Creative design/Website',
    includes: 'Ad Design & Creative design/Website',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'Ads on your website',
    includes: 'Ads on your website',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'Advertising Concepts',
    includes: 'Advertising Concepts',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'Advertising Management',
    includes: 'Advertising Management',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71129,
    description: 'Aerospace Engineering & Architecture',
    includes: 'Aerospace Engineering & Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71129,
    description: 'Analog Engineering & Architecture',
    includes: 'Analog Engineering & Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 59121,
    description: 'Animation',
    includes: 'Animation',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71111,
    description: 'Architecture',
    includes: 'Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 90031,
    description: 'Article & Blog Writing & Translations',
    includes: 'Article & Blog Writing & Translations',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 59201,
    description: 'Audio Production',
    includes: 'Audio Production',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Branding Services',
    includes: 'Branding Services',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Budgeting/Forecasting',
    includes: 'Budgeting/Forecasting',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82111,
    description: 'Bulk mailing',
    includes: 'Bulk mailing',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74101,
    description: 'Business Consulting Card Design & Creative design',
    includes: 'Business Consulting Card Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Business Consulting Development',
    includes: 'Business Consulting Development',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Business Consulting Management',
    includes: 'Business Consulting Management',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Business Consulting Planning',
    includes: 'Business Consulting Planning',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71121,
    description: 'CAD Drafting/Design & Creative design',
    includes: 'CAD Drafting/Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 66191,
    description: 'CFO Services',
    includes: 'CFO Services',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Career Advice',
    includes: 'Career Advice',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Chatbots',
    includes: 'Chatbots',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71122,
    description: 'Chemical Engineering & Architecture',
    includes: 'Chemical Engineering & Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 42991,
    description: 'Civil & Structural Engineering & Architecture',
    includes: 'Civil & Structural Engineering & Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 63111,
    description: 'Cloud hosting',
    includes: 'Cloud hosting',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74201,
    description: 'Commercial/Advertising Photography',
    includes: 'Commercial/Advertising Photography',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74201,
    description: 'Commercial/Advertising Videography',
    includes: 'Commercial/Advertising Videography',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69201,
    description: 'Company Valuation',
    includes: 'Company Valuation',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Competitive Analysis',
    includes: 'Competitive Analysis',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Computer Games',
    includes: 'Computer Games',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Conflict Management',
    includes: 'Conflict Management',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69103,
    description: 'Contract Law',
    includes: 'Contract Law',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'CopyWriting & Translations',
    includes: 'CopyWriting & Translations',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Corporate Communication',
    includes: 'Corporate Communication',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69103,
    description: 'Corporate Law',
    includes: 'Corporate Law',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69202,
    description: 'Cost Analysis/Reduction',
    includes: 'Cost Analysis/Reduction',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 90031,
    description: 'Creative Writing & Translations',
    includes: 'Creative Writing & Translations',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69102,
    description: 'Criminal Law',
    includes: 'Criminal Law',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82201,
    description: 'Customer Service',
    includes: 'Customer Service',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71209,
    description: 'Data Science & Analytics Entry',
    includes: 'Data Science & Analytics Entry',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71209,
    description: 'Data Science & Analytics Extraction / ETL',
    includes: 'Data Science & Analytics Extraction / ETL',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71209,
    description: 'Data Science & Analytics Mining & Management',
    includes: 'Data Science & Analytics Mining & Management',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71209,
    description: 'Data Science & Analytics Visualization',
    includes: 'Data Science & Analytics Visualization',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71209,
    description: 'Data Science & Analytics and database Design',
    includes: 'Data Science & Analytics and database Design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71209,
    description: 'Data Science & Analytics and database Programming',
    includes: 'Data Science & Analytics and database Programming',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71209,
    description: 'Data Science & Analytics and databases',
    includes: 'Data Science & Analytics and databases',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Desktop Software Development',
    includes: 'Desktop Software Development',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'Digital Marketing services',
    includes: 'Digital Marketing services',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'Direct Marketing/Advertising',
    includes: 'Direct Marketing/Advertising',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'ERP/CRM Programming',
    includes: 'ERP/CRM Programming',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Ecommerce Development',
    includes: 'Ecommerce Development',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82191,
    description: 'Editing & Proofreading',
    includes: 'Editing & Proofreading',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 59111,
    description: 'Educational videos',
    includes: 'Educational videos',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71129,
    description: 'Electrical Engineering & Architecture',
    includes: 'Electrical Engineering & Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'Email & Marketing Automation',
    includes: 'Email & Marketing Automation',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71122,
    description: 'Environmental Engineering & Architecture',
    includes: 'Environmental Engineering & Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 58121,
    description: 'Fact Checking',
    includes: 'Fact Checking',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69103,
    description: 'Family Law',
    includes: 'Family Law',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82111,
    description: 'Financial Planning',
    includes: 'Financial Planning',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69202,
    description: 'Financial Reporting',
    includes: 'Financial Reporting',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Game Development',
    includes: 'Game Development',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71122,
    description: 'Geotechnical Engineering & Architecture',
    includes: 'Geotechnical Engineering & Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82191,
    description: 'Grants/Proposals',
    includes: 'Grants/Proposals',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 59121,
    description: 'Graphic Design & Creative design',
    includes: 'Graphic Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'Grassroot Marketing/Advertising',
    includes: 'Grassroot Marketing/Advertising',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71111,
    description: 'House Architecture',
    includes: 'House Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 78301,
    description: 'Human Resources',
    includes: 'Human Resources',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'IT support',
    includes: 'IT support',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 59121,
    description: 'Illustration',
    includes: 'Illustration',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74901,
    description: 'Industrial Engineering & Architecture',
    includes: 'Industrial Engineering & Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'Influencer Marketing',
    includes: 'Influencer Marketing',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71129,
    description: 'Integrated Circuit Design & Creative design',
    includes: 'Integrated Circuit Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69109,
    description: 'Intellectual Property Law',
    includes: 'Intellectual Property Law',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74101,
    description: 'Interior Design & Creative design',
    includes: 'Interior Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'International Commerce',
    includes: 'International Commerce',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Investor Relations',
    includes: 'Investor Relations',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74201,
    description: 'Journalistic Photography',
    includes: 'Journalistic Photography',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71111,
    description: 'Landscape Architecture',
    includes: 'Landscape Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Lead Generation',
    includes: 'Lead Generation',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Leadership Development',
    includes: 'Leadership Development',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Legal Consulting Writing & Translations',
    includes: 'Legal Consulting Writing & Translations',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74101,
    description: 'Logo Design & Creative design & Branding',
    includes: 'Logo Design & Creative design & Branding',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71209,
    description: 'Machine Learning',
    includes: 'Machine Learning',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82111,
    description: 'Mailing List Development',
    includes: 'Mailing List Development',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Management Consulting',
    includes: 'Management Consulting',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Management Training & Education',
    includes: 'Management Training & Education',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Manufacturing Planning/Management',
    includes: 'Manufacturing Planning/Management',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'Market & Customer Service Research',
    includes: 'Market & Customer Service Research',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Market Research/Planning',
    includes: 'Market Research/Planning',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'Marketing Strategy',
    includes: 'Marketing Strategy',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74901,
    description: 'Material Engineering & Architecture',
    includes: 'Material Engineering & Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74901,
    description: 'Mechanical Engineering & Architecture',
    includes: 'Mechanical Engineering & Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82191,
    description: 'Medical Writing & Translations/Editing',
    includes: 'Medical Writing & Translations/Editing',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Memory Training & Education',
    includes: 'Memory Training & Education',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Mergers & Acquisitions',
    includes: 'Mergers & Acquisitions',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Mobile Development',
    includes: 'Mobile Development',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Mobile apps',
    includes: 'Mobile apps',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 59201,
    description: 'Music tracks',
    includes: 'Music tracks',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74201,
    description: 'News Photography',
    includes: 'News Photography',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82191,
    description: 'Newsletters Writing & Translations/Editing',
    includes: 'Newsletters Writing & Translations/Editing',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71111,
    description: 'Office Architecture',
    includes: 'Office Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 58291,
    description: 'Online games',
    includes: 'Online games',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 47191,
    description: 'Online shop, e-Commerce, FBA or dropshipping',
    includes: 'Online shop, e-Commerce, FBA or dropshipping',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 72191,
    description: 'Operations Research',
    includes: 'Operations Research',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69202,
    description: 'Other Accounting & Financing & Finance',
    includes: 'Other Accounting & Financing & Finance',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82991,
    description: 'Other Admin support',
    includes: 'Other Admin support',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Other Business Consulting',
    includes: 'Other Business Consulting',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71209,
    description: 'Other Data Science & Analytics Science & Analytics',
    includes: 'Other Data Science & Analytics Science & Analytics',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 59121,
    description: 'Other Design & Creative design & Creative',
    includes: 'Other Design & Creative design & Creative',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74901,
    description: 'Other Engineering & Architecture',
    includes: 'Other Engineering & Architecture',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62031,
    description: 'Other IT & Networking',
    includes: 'Other IT & Networking',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69109,
    description: 'Other Legal Consulting',
    includes: 'Other Legal Consulting',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Other Sales & Marketings & Marketing',
    includes: 'Other Sales & Marketings & Marketing',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Other Software Development',
    includes: 'Other Software Development',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82191,
    description: 'Other Writing & Translations',
    includes: 'Other Writing & Translations',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62091,
    description: 'Other- Digital services',
    includes: 'Other- Digital services',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62091,
    description: 'Other- E-services',
    includes: 'Other- E-services',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Other- Training & Education',
    includes: 'Other- Training & Education',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Packaged Software Development',
    includes: 'Packaged Software Development',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69109,
    description: 'ParaLegal Consulting Services',
    includes: 'ParaLegal Consulting Services',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Personal / Virtual Assistant',
    includes: 'Personal / Virtual Assistant',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74201,
    description: 'Photographs/Design & Creative designs',
    includes: 'Photographs/Design & Creative designs',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74201,
    description: 'Photography',
    includes: 'Photography',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 59201,
    description: 'Podcasts',
    includes: 'Podcasts',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74201,
    description: 'Presentation Design & Creative design',
    includes: 'Presentation Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Presentation Skills',
    includes: 'Presentation Skills',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74101,
    description: 'Product Design & Creative design',
    includes: 'Product Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62031,
    description: 'Product Management',
    includes: 'Product Management',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Professional Development',
    includes: 'Professional Development',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62021,
    description: 'Programming Languages/Tools',
    includes: 'Programming Languages/Tools',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Project Management',
    includes: 'Project Management',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70211,
    description: 'Public Relations',
    includes: 'Public Relations',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'QA & Testing',
    includes: 'QA & Testing',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71209,
    description: 'Quantitative Analysis',
    includes: 'Quantitative Analysis',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 72191,
    description: 'Research papers',
    includes: 'Research papers',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82191,
    description: 'Resumes & Cover Letters',
    includes: 'Resumes & Cover Letters',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'SEM - Search Engineering & Marketing',
    includes: 'SEM - Search Engineering & Marketing',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 94129,
    description: 'SEO - Search Engineering & Optimization',
    includes: 'SEO - Search Engineering & Optimization',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 73111,
    description: 'SMM - Social Media Marketing',
    includes: 'SMM - Social Media Marketing',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Sales & Marketings Training & Education',
    includes: 'Sales & Marketings Training & Education',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 72191,
    description: 'Scientific Computation',
    includes: 'Scientific Computation',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 58291,
    description: 'Screen/Script Writing & Translations/Editing',
    includes: 'Screen/Script Writing & Translations/Editing',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Scripts & Utilities',
    includes: 'Scripts & Utilities',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Security Design & Creative design/Programming',
    includes: 'Security Design & Creative design/Programming',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Self Improvement',
    includes: 'Self Improvement',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74101,
    description: 'Social Media Design & Creative design',
    includes: 'Social Media Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Software Development as a Service',
    includes: 'Software Development as a Service',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Speed Reading',
    includes: 'Speed Reading',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Stress Management',
    includes: 'Stress Management',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 69202,
    description: 'Tax Planning',
    includes: 'Tax Planning',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Team Building',
    includes: 'Team Building',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Technical Project Management',
    includes: 'Technical Project Management',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62021,
    description: 'Technical Support',
    includes: 'Technical Support',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62021,
    description: 'Technical Training & Education',
    includes: 'Technical Training & Education',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 58291,
    description: 'Technical Writing & Translations',
    includes: 'Technical Writing & Translations',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82201,
    description: 'Telemarketing & TeleSales & Marketings',
    includes: 'Telemarketing & TeleSales & Marketings',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 58291,
    description: 'Templates (e.g. e-mails, websites etc)',
    includes: 'Templates (e.g. e-mails, websites etc)',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Time Management',
    includes: 'Time Management',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82191,
    description: 'Transcription',
    includes: 'Transcription',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74301,
    description: 'Translation',
    includes: 'Translation',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'User Interface Design & Creative design',
    includes: 'User Interface Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 58291,
    description: 'VPN services',
    includes: 'VPN services',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74201,
    description: 'Vector Tracing',
    includes: 'Vector Tracing',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 70221,
    description: 'Venture Funding',
    includes: 'Venture Funding',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 59111,
    description: 'Video Production',
    includes: 'Video Production',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 74901,
    description: 'Voice Talent',
    includes: 'Voice Talent',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Web & Mobile Design & Creative design',
    includes: 'Web & Mobile Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 71209,
    description: 'Web Analytics',
    includes: 'Web Analytics',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 63121,
    description: 'Web Content',
    includes: 'Web Content',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Web Development',
    includes: 'Web Development',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 63121,
    description: 'Web Research',
    includes: 'Web Research',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 59121,
    description: 'Web graphics and vectors',
    includes: 'Web graphics and vectors',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 63111,
    description: 'Web services',
    includes: 'Web services',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 85599,
    description: 'Webinars',
    includes: 'Webinars',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Website Builders & CMS',
    includes: 'Website Builders & CMS',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'Website Design & Creative design',
    includes: 'Website Design & Creative design',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 82191,
    description: 'Word Processing',
    includes: 'Word Processing',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 62011,
    description: 'WordPress',
    includes: 'WordPress',
    allowed: true,
    needs_license: false,
    warning: ''
  },
  {
    code: 58111,
    description: 'EBooks',
    includes: 'EBooks',
    allowed: true,
    needs_license: false,
    warning: ''
  }
];
export default emtak;
