// eslint-disable-next-line import/no-extraneous-dependencies
import { IntlShape } from '@formatjs/intl/src/types';
import {
  isValidE164PhoneNumber,
  isValidEmail,
  isValidEresidencyCodeOrBirthDateDDMMYYYY,
  isValidNumber,
  isValidString
} from '../../../../common/utils/validations';
import { MergedState, NewMemberState } from '../../types';

const fieldValidation = (
  key: string,
  value: any,
  currentStatus: MergedState,
  intl: IntlShape,
  isSwitch: boolean,
  unsupportedCountriesList: string[]
) => {
  const { company, members, modalAction, updatingMemberIndex } = currentStatus;

  function emailValidation(email: string) {
    if (!isValidString(email) || !isValidEmail(email)) {
      return intl.formatMessage({ id: 'invalid_email', defaultMessage: 'Enter a valid email.' });
    }
    return null;
  }

  function phoneValidation(phone: string) {
    if (!isValidE164PhoneNumber(phone, false)) {
      return intl.formatMessage({
        id: 'invalid_phone',
        defaultMessage: 'Enter a correct phone number, example: +37212345678'
      });
    }
    return null;
  }

  function sharedCapitalValidation(sharedCapital: number) {
    if (!isValidNumber(sharedCapital) || sharedCapital > 50000 || sharedCapital < 1) {
      return intl.formatMessage({
        id: 'invalid_share_capital',
        defaultMessage: 'Enter a valid amount between €1 and €50000.'
      });
    }
    return null;
  }

  function activityDescriptionValidation(activityDescription: string) {
    if (!isValidString(activityDescription)) {
      return intl.formatMessage({
        id: 'invalid_description',
        defaultMessage: 'Enter a valid description.'
      });
    }
    return null;
  }

  function firstNameValidation(firstName: string) {
    if (!isValidString(firstName)) {
      return intl.formatMessage({
        id: 'invalid_first_name',
        defaultMessage: 'Enter a valid first name.'
      });
    }
    return null;
  }

  function lastNameValidation(lastName: string) {
    if (!isValidString(lastName)) {
      return intl.formatMessage({
        id: 'invalid_last_name',
        defaultMessage: 'Enter a valid last name.'
      });
    }
    return null;
  }

  function shareholderContributionValidation(contribution: number) {
    const parsedContribution = Number(contribution);
    if (!isValidNumber(parsedContribution) || parsedContribution < 0) {
      return intl.formatMessage({
        id: 'invalid_amount',
        defaultMessage: 'Enter a valid amount.'
      });
    }

    let unassignedCapital = 0;
    if (modalAction === 'Add') {
      unassignedCapital = company.unassigned_capital;
    } else if (modalAction === 'Update') {
      const currentShareholderContribuition = isValidNumber(
        Number(members[updatingMemberIndex].shareholder_contribution)
      )
        ? parseFloat(members[updatingMemberIndex].shareholder_contribution)
        : 0;
      unassignedCapital = company.unassigned_capital + currentShareholderContribuition;
    }

    if (parsedContribution > unassignedCapital) {
      // return `Enter a value up to €${unassignedCapital}`;
      return `${intl.formatMessage({
        id: 'enter_a_value_up_to',
        defaultMessage: 'Enter a value up to'
      })} €${unassignedCapital}`;
    }
    return null;
  }

  function addressValidation(address: string) {
    if (!isValidString(address)) {
      return intl.formatMessage({
        id: 'invalid_address',
        defaultMessage: 'Enter a valid address.'
      });
    }
    return null;
  }

  function cityValidation(city: string) {
    if (!isValidString(city)) {
      return intl.formatMessage({
        id: 'invalid_city_name',
        defaultMessage: 'Enter a valid city name.'
      });
    }
    return null;
  }

  function postalCodeValidation(postalCode: number) {
    if (!isValidString(postalCode)) {
      return intl.formatMessage({
        id: 'invalid_postal_code',
        defaultMessage: 'Enter a valid postal code.'
      });
    }
    return null;
  }

  function eResidentIdValidation(eResidentId: string) {
    if (
      !isValidString(eResidentId) ||
      !isValidEresidencyCodeOrBirthDateDDMMYYYY(eResidentId, false)
    ) {
      return intl.formatMessage({
        id: 'invalid_e_resident_code',
        defaultMessage:
          "Enter a valid e-Residency code, (it has 11 digits and starts with '3', '4', '5' or '6')."
      });
    }
    return null;
  }

  function validatePep(pep: boolean) {
    if (pep) {
      return intl.formatMessage({
        id: 'we_cant_serve_pep',
        defaultMessage:
          "We can't offer services to politically exposed people. If uncertain, it is better for you to stop the registration process and contact us beforehand."
      });
    }
    return null;
  }

  function validateRegistryCode(registryCode: string) {
    const numericRegistryCode = parseInt(registryCode, 10);
    const stringRegistryCode = numericRegistryCode.toString();
    if (Number.isNaN(numericRegistryCode) || stringRegistryCode.length !== 8) {
      return intl.formatMessage({
        id: 'invalid_registry_number',
        defaultMessage:
          'Enter a valid registry number, it must be an 8-digit long number, and not start with 0, (example: 14522788).'
      });
    }
    return null;
  }

  function validateMembers(memberArray: NewMemberState[]) {
    const representatives = memberArray.filter((m) => m.is_representative);
    const shareholders = memberArray.filter((m) => m.shareholder);
    const boardMembers = memberArray.filter((m) => m.board_member);

    if (!Array.isArray(memberArray) || memberArray.length < 1) {
      return intl.formatMessage({
        id: 'invalid_members',
        defaultMessage: 'Before proceeding to the next step, you must add at least ONE member.'
      });
    }

    // no representative
    if (representatives.length < 1) {
      return intl.formatMessage({
        id: 'representative_not_shareholder_board_member',
        defaultMessage:
          'No board member or shareholder has been appointed as representative. Please, choose at least one member as representative and try again.'
      });
    }
    if (isSwitch) {
      if (boardMembers.length < 1) {
        return intl.formatMessage({
          id: 'no_board_member_selected_switching',
          defaultMessage:
            'No board members have been specified. Please, add at least one board member.'
        });
      }
    } else if (shareholders.length < 1 || boardMembers.length < 1) {
      return intl.formatMessage({
        id: 'shareholder_and_board_members_missing',
        defaultMessage:
          'No shareholders or board members have been specified. Please, add at least one shareholder and one board member. If your company is just you, add yourself as board member, shareholder and representative.'
      });
    }

    const unassignedCapital =
      Number(company.share_capital) -
      memberArray.reduce((a, i) => a + Number(i.shareholder_contribution), 0);
    if (unassignedCapital > 0) {
      return `${intl.formatMessage({
        id: 'unassigned_company_share_capital',
        defaultMessage: 'Unassigned share capital:'
      })}€${unassignedCapital.toFixed(1)}`;
    }

    const shareholdersWithOutContribution = memberArray.filter((m) => {
      if (m.shareholder) {
        return !m.shareholder_contribution || parseFloat(m.shareholder_contribution || '0') === 0;
      }
      return false;
    });
    if (shareholdersWithOutContribution.length) {
      return `${intl.formatMessage({
        id: 'no_defined_share_contribution',
        defaultMessage:
          'There are shareholders without share capital contribution defined. Use the color bar below each member to defined the contribution.'
      })}`;
    }
    return null;
  }

  function countriesValidation(country: string) {
    if (unsupportedCountriesList.includes(country)) {
      return intl.formatMessage({
        id: 'unsupported_country_text',
        defaultMessage:
          'Sorry, we do not support business owners from this country currently, Please try with another service provider.'
      });
    }
    return null;
  }

  if (key === 'email') {
    return emailValidation(value);
  }
  if (key === 'activity_description') {
    return activityDescriptionValidation(value);
  }
  if (key === 'phone') {
    return phoneValidation(value);
  }
  if (key === 'share_capital') {
    return sharedCapitalValidation(value);
  }
  if (key === 'first_name') {
    return firstNameValidation(value);
  }
  if (key === 'last_name') {
    return lastNameValidation(value);
  }
  if (key === 'shareholder_contribution') {
    return shareholderContributionValidation(value);
  }
  if (key === 'address') {
    return addressValidation(value);
  }
  if (key === 'city') {
    return cityValidation(value);
  }
  if (key === 'postal_code') {
    return postalCodeValidation(value);
  }
  if (key === 'eresident_id') {
    return eResidentIdValidation(value);
  }
  if (key === 'pep') {
    return validatePep(value);
  }
  if (key === 'members') {
    return validateMembers(value);
  }

  if (key === 'registry_number') {
    return validateRegistryCode(value);
  }
  if (key === 'address_country') {
    return countriesValidation(value);
  }

  return null;
};

export default fieldValidation;
