import React from 'react';
import { Button } from '@mui/material';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import { withErrorBoundary } from '@sentry/react';

type RegistrationButtonProps = {
  id?: string;
  onClick?: Function;
  variant?: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  type?: string;
  disabled?: boolean;
  children: React.ReactNode;
  direction?: string;
  sx?: any;
};

function RegistrationButton({
  id,
  onClick,
  variant,
  size,
  className,
  type,
  disabled,
  children,
  direction,
  sx
}: RegistrationButtonProps) {
  return (
    // @ts-ignore
    <Button
      id={id}
      name={id}
      variant="outlined"
      data-testid="registration_button"
      className={className}
      onClick={(e) => (onClick ? onClick(e) : null)}
      size={size}
      margin="none"
      type={type}
      disabled={disabled}
      style={{
        boxShadow: variant === 'outlined' ? 'none' : '0px 4px 12px rgba(55, 81, 255, 0.24)'
      }}
      sx={{
        maxHeight: '48px',
        height: '100%',
        textTransform: 'none',
        fontFamily: 'TEEE',
        fontWeight: 500,
        backgroundColor: variant === 'outlined' ? 'transparent' : '#FF4EA1',
        color: variant === 'outlined' ? '#253292' : '#FFFFFF',
        border: variant === 'outlined' ? 'solid #253292 2px' : 'solid #FF4EA1 2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '31px',
        transaction: 'all 300ms',
        ':hover': {
          border: variant === 'outlined' ? 'solid #253292 2px' : 'solid #FF4EA1 2px',
          backgroundColor: variant === 'outlined' ? 'transparent' : '#FF4EA1',
          opacity: 0.7
        },
        ':disabled': {
          border: variant === 'outlined' ? 'solid #253292 2px' : 'solid #FF4EA1 2px',
          backgroundColor: variant === 'outlined' ? 'transparent' : '#FF4EA1',
          color: variant === 'outlined' ? '#253292' : '#FFFFFF',
          opacity: 0.3
        },
        ...sx
      }}>
      <ArrowBack sx={{ color: direction === 'back' ? '' : 'transparent' }} />
      {children}
      <ArrowForward sx={{ color: direction === 'forward' ? '' : 'transparent' }} />
    </Button>
  );
}

export default withErrorBoundary(RegistrationButton, {
  fallback: <div>Failed to load button</div>
});
