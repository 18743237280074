import { withErrorBoundary } from '@sentry/react';
import React from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  PAYMENT_STATUS_CANCELED,
  PAYMENT_STATUS_EXPIRED,
  PAYMENT_STATUS_FAILED,
  PAYMENT_STATUS_PROCESSING,
  PAYMENT_STATUS_REQUIRES_ACTION,
  PAYMENT_STATUS_REQUIRES_PAYMENT_METHOD
} from '../../../../common/utils/constants';
import RegistrationButton from '../../../../common/components/RegistrationButton';
import { isValidString } from '../../../../common/utils/validations';
import { RegPaymentInfo } from '../../../../app/interfaces';

function RegistrationPaymentStatus({
  regPaymentInfo,
  setIsRetryingPayment,
  handlePayment
}: {
  regPaymentInfo: RegPaymentInfo;
  setIsRetryingPayment: Function;
  handlePayment: Function;
}) {
  const intl = useIntl();

  const paymentStatus = regPaymentInfo.payment.status;

  let message = '';
  let messageTitle = '';
  let buttonTittle = '';
  if (
    paymentStatus === PAYMENT_STATUS_FAILED ||
    paymentStatus === PAYMENT_STATUS_REQUIRES_ACTION ||
    paymentStatus === PAYMENT_STATUS_REQUIRES_PAYMENT_METHOD
  ) {
    messageTitle = intl.formatMessage({
      id: 'payment_failed_title',
      defaultMessage: 'Payment failed!'
    });
    message = intl.formatMessage({
      id: 'your_payment_failed',
      defaultMessage:
        "Your registration payment didn't go through. Please ensure your payment method has enough funds and click below to try again. \uD83D\uDC47\uD83C\uDFFC"
    });
    buttonTittle = intl.formatMessage({
      id: 'retry_payment',
      defaultMessage: 'Retry payment'
    });
  }
  if (paymentStatus === PAYMENT_STATUS_PROCESSING) {
    messageTitle = intl.formatMessage({
      id: 'processing_payment',
      defaultMessage: 'Processing payment'
    });
    message = intl.formatMessage({
      id: 'payment_status_processing',
      defaultMessage:
        'Your payment is processing. This can take up to a few days depending on the payment method. We will update you when the payment is processed. You can refresh this page to check if the payment status has changed.'
    });
  }
  if (paymentStatus === PAYMENT_STATUS_CANCELED || paymentStatus === PAYMENT_STATUS_EXPIRED) {
    messageTitle = intl.formatMessage({
      id: 'payment_canceled_or_expired_title',
      defaultMessage: 'Payment Canceled!⚠️'
    });
    message = intl.formatMessage({
      id: 'payment_canceled_or_expired_message',
      defaultMessage:
        "It seems your payment has either been canceled or expired. No stress! Just click below, double-check your payment details, and let's try that again. We've got your back! \uD83D\uDE0A"
    });
    buttonTittle = intl.formatMessage({
      id: 'retry_payment',
      defaultMessage: 'Retry payment'
    });
  }
  return (
    <Box
      className="flex flex-col items-center justify-center mt-5"
      style={{ maxWidth: '411px', width: '100%' }}>
      <p className="registration-text-large-blue mt-5">{messageTitle}</p>
      <p className="registration-text-medium-gray mt-5 mb-8">{message}</p>
      <div className="flex justify-center mt-5">
        {isValidString(buttonTittle) && (
          <RegistrationButton
            className="registration-button-w md:p10"
            type="submit"
            variant="filled"
            onClick={async (e: MouseEvent) => {
              e.preventDefault();
              if (
                paymentStatus === PAYMENT_STATUS_EXPIRED ||
                paymentStatus === PAYMENT_STATUS_CANCELED
              ) {
                await handlePayment('restart');
              }
              setIsRetryingPayment(true);
            }}
            size="large">
            {intl.formatMessage({ id: 'retry_payment', defaultMessage: 'Retry payment' })}
          </RegistrationButton>
        )}
      </div>
    </Box>
  );
}

export default withErrorBoundary(RegistrationPaymentStatus, {
  fallback: <div>Failed to load</div>
});
