import { useEffect } from 'react';

const useCrispChat = () => {
  useEffect(() => {
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_CHAT_ID;

    const script = document.createElement('script');

    script.src = 'https://client.crisp.chat/l.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useCrispChat;
