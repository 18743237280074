import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { CSSProperties } from 'react';
import { withErrorBoundary } from '@sentry/react';

type RegistrationTextInputT = {
  id: string;
  placeholder: string;
  value: string;
  onChange: Function;
  style?: CSSProperties;
  className?: string;
  label?: React.ReactNode;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
  helperText?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
};

export const StyledTextInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#F0F1F7'
    },
    '& .MuiInputLabel-root': {
      display: 'none'
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'TEEE',
      fontWeight: 400,
      backgroundColor: '#FCFDFE',
      borderRadius: '8px',
      color: '#4B506D',
      '& fieldset': {
        borderColor: '#F0F1F7'
      },
      '&:hover fieldset': {
        border: 'none'
      },
      '& .Mui-focused fieldset': {
        borderColor: '#F0F1F7'
      }
    }
  }
})(TextField);

export const borderColor = (error?: boolean, success?: boolean, warning?: boolean) => {
  if (error) {
    return '#FE2142';
  }
  if (warning) {
    return '#ffc107';
  }
  if (success) {
    return '#00DA8B';
  }

  return '#F0F1F7';
};

/* istanbul ignore next */
export const adornment = (error?: boolean, success?: boolean, warning?: boolean) => {
  if (error) {
    return <CancelIcon style={{ color: '#FE2142' }} />;
  }

  if (warning) {
    return <CheckCircleIcon style={{ color: '#ffc107' }} />;
  }

  if (success) {
    return <CheckCircleIcon style={{ color: '#00DA8B' }} />;
  }

  return null;
};

function RegistrationTextInput({
  id,
  placeholder,
  value,
  onChange,
  style,
  className,
  label,
  children,
  disabled,
  error,
  success,
  warning,
  helperText,
  required
}: RegistrationTextInputT) {
  return (
    <div
      className={`d-flex flex-col items-start justify-center ${className}`}
      style={style}
      data-testid="registration_text_input_wrapper">
      {label && (
        <p
          className="text-start uppercase"
          style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
          {label}
          {required && <strong style={{ color: '#FF4EA1' }}> * </strong>}
        </p>
      )}
      <StyledTextInput
        disabled={disabled}
        variant="outlined"
        data-testid="registration_text_input"
        fullWidth
        margin="none"
        required
        placeholder={placeholder}
        id={id}
        autoComplete={id}
        size="small"
        value={value}
        onChange={(e) => onChange(e)}
        InputProps={{
          endAdornment: adornment(error, success, warning)
        }}
        style={{
          backgroundColor: 'transparent',
          borderColor: borderColor(error, success, warning),
          borderWidth: '1px',
          borderStyle: 'solid',
          padding: '0px',
          borderRadius: '8px'
        }}>
        {children}
      </StyledTextInput>
      {(error || success || warning) && helperText !== '' && (
        <p
          className="text-start"
          data-testid="registration_text_input_helper_text"
          style={{
            fontWeight: 400,
            color: borderColor(error, success, warning),
            fontSize: 12,
            marginTop: '0.7rem'
          }}>
          {' '}
          {helperText}{' '}
        </p>
      )}
    </div>
  );
}

export default withErrorBoundary(RegistrationTextInput, {
  fallback: <div>Failed to load text input component</div>
});
