const callLinkedinTagEvent = (conversionId: number) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') return;
  // @ts-ignore
  if (window.lintrk) {
    // @ts-ignore
    window.lintrk('track', { conversion_id: conversionId });
  }
};

export default callLinkedinTagEvent;
