import React from 'react';
import { Outlet } from 'react-router-dom';
import { isValidString } from '../../common/utils/validations';
import { getAuthToken } from '../../common/utils/helpers';
import { AUTH_TOKEN_KEY, FRONT_END_FALLBACK_URL } from '../../common/utils/constants';

function AuthValidate() {
  const token = getAuthToken();
  try {
    if (!isValidString(token)) {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      window.location.href = process.env.REACT_APP_FRONTEND_URL || FRONT_END_FALLBACK_URL;
    }
  } catch {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    window.location.href = process.env.REACT_APP_FRONTEND_URL || FRONT_END_FALLBACK_URL;
  }
  localStorage.setItem(AUTH_TOKEN_KEY, token);
  return <Outlet />;
}
export default AuthValidate;
