import { Box } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { withErrorBoundary } from '@sentry/react';
import { CompanyState, PaymentInfoState } from '../types';
import RegistrationCheckbox from '../../../common/components/RegistrationCheckbox';

function PaymentInfoRow(key: string, value: string, highlighted: boolean = false) {
  return (
    <>
      <div
        className="flex align-center justify-between"
        style={{
          width: '100%',
          padding: '20px',
          background: highlighted ? '#F4F5FA' : ''
        }}>
        <p className="registration-text-16-14" style={{ fontWeight: 500, color: '#4B506D' }}>
          {key}
        </p>
        <p
          className="registration-text-16-14"
          style={{
            fontWeight: 400,
            color: highlighted ? '#4B506D' : '#9FA2B4',
            fontSize: highlighted ? '18px' : '16px'
          }}>
          {value}
        </p>
      </div>
      <hr style={{ width: '100%', background: '#DFE0EB' }} />
    </>
  );
}

function PaymentForm({
  paymentInfo,
  handlePaymentInfoCheckboxChange,
  isSwitch,
  company
}: {
  paymentInfo: PaymentInfoState;
  handlePaymentInfoCheckboxChange: Function;
  isSwitch: boolean;
  company: CompanyState;
}) {
  const intl: any = useIntl();
  return (
    <Box
      className="flex flex-col md:items-start items-center p-0 mt-5"
      style={{ maxWidth: '650px', width: '100%' }}>
      <p className="registration-text-large-blue">
        <FormattedMessage
          id={isSwitch ? 'payment_summary' : 'step_4_title'}
          defaultMessage={isSwitch ? 'Payment summary' : '"Overview & Payment"'}
        />{' '}
      </p>
      <p className="registration-text-medium-gray mt-5 mb-5">
        {' '}
        <FormattedMessage
          id={isSwitch ? 'no_charge' : 'step_4_subtitle'}
          defaultMessage={
            isSwitch
              ? `You're just one step away! Please review the payment details below carefully; 
                the next screen will process the payment via credit card, and there's no going back. Double-check your information now.`
              : 'As promised, we’re not charging you a single euro for switching.'
          }
        />
      </p>
      {PaymentInfoRow(
        intl.formatMessage({
          id: 'company_registration_cost_label',
          defaultMessage: 'Company Registration'
        }),
        `€${paymentInfo.base / 100}`
      )}
      {paymentInfo.members > 1 &&
        PaymentInfoRow(
          intl.formatMessage({
            id: 'extra_member',
            defaultMessage: 'Extra member'
          }),
          `${paymentInfo.members - 1} x €50 = ${(paymentInfo.members - 1) * 50}`
        )}
      {paymentInfo.discount > 0 &&
        PaymentInfoRow(
          intl.formatMessage({ id: 'discount', defaultMessage: 'Discount' }),
          `-€${paymentInfo.discount / 100}`,
          true
        )}
      {paymentInfo.vat > 0 &&
        PaymentInfoRow(
          intl.formatMessage({ id: 'applicable_vat', defaultMessage: 'Applicable VAT' }),
          `€${paymentInfo.vat / 100} (${paymentInfo.vat_percentage * 100}%)`
        )}

      {PaymentInfoRow('Total', `€${paymentInfo.total / 100}`)}
      <div>
        <div className="w-full text-left flex items-start md:items-center mt-5">
          <RegistrationCheckbox
            className="checkbox-adjustment"
            id="privacy_policy"
            data-testid="checkbox"
            name="privacy_policy"
            checked={paymentInfo.privacy_policy}
            onChange={handlePaymentInfoCheckboxChange}
            label={
              <p
                className="w-full"
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#9FA2B4',
                  fontFamily: 'TEEE'
                }}>
                <FormattedMessage id="i_accept" defaultMessage="I accept the" />{' '}
                <a target="_blank" rel="noreferrer" href="https://companio.co/terms-of-service/">
                  <FormattedMessage
                    id="terms_and_conditions"
                    defaultMessage="Terms and Conditions"
                  />{' '}
                </a>{' '}
                &{' '}
                <a target="_blank" rel="noreferrer" href="https://companio.co/privacy-policy/">
                  <FormattedMessage id="privacy_policy" defaultMessage="privacy policy" />
                </a>
                <strong style={{ color: '#FF4EA1' }}> * </strong>
              </p>
            }
          />
        </div>
        {company.company_type === 'virtual' ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        ) : (
          <div className="w-full text-left flex items-start md:items-center mt-5">
            <RegistrationCheckbox
              id="accountancy"
              data-testid="checkbox"
              name="accountancy"
              checked={paymentInfo.accountancy}
              onChange={handlePaymentInfoCheckboxChange}
              label={
                <p
                  className="pl-2 md:pl-0 md:ml-0"
                  style={{
                    fontWeight: 400,
                    fontSize: '12px',
                    color: '#9FA2B4',
                    width: '100%',
                    fontFamily: 'TEEE'
                  }}>
                  <FormattedMessage
                    id={isSwitch ? 'i_accept_accountancy_1_switch' : 'i_accept_accountancy_1'}
                    defaultMessage={
                      isSwitch
                        ? 'I understand and agree that my company is subject to a paid monthly subscription plan since my company has effectively switched to Companio.'
                        : 'I understand and agree that the registration of my company is subject to subscription to a paid monthly accounting plan from the moment the company is registered.'
                    }
                  />{' '}
                  <a target="_blank" rel="noreferrer" href="https://companio.co/pricing/">
                    <FormattedMessage id="i_accept_accountancy_2" defaultMessage="See prices" />
                  </a>
                  <strong style={{ color: '#FF4EA1' }}> * </strong>
                </p>
              }
            />
          </div>
        )}
      </div>
    </Box>
  );
}

export default withErrorBoundary(PaymentForm, {
  fallback: <div>Failed to load</div>
});
