type Country = {
  code: string;
  name: string;
  icon: string;
};

const countriesData: Country[] = [
  {
    code: 'AF',
    name: 'Afghanistan',
    icon: 'assets/img/icons/flags/afghanistan.svg'
  },
  {
    code: 'AX',
    name: 'Åland Islands',
    icon: 'assets/img/icons/flags/aland-islands.svg'
  },
  {
    code: 'AL',
    name: 'Albania',
    icon: 'assets/img/icons/flags/albania.svg'
  },
  {
    code: 'DZ',
    name: 'Algeria',
    icon: 'assets/img/icons/flags/algeria.svg'
  },
  {
    code: 'AS',
    name: 'American Samoa',
    icon: 'assets/img/icons/flags/american-samoa.svg'
  },
  {
    code: 'AD',
    name: 'Andorra',
    icon: 'assets/img/icons/flags/andorra.svg'
  },
  {
    code: 'AO',
    name: 'Angola',
    icon: 'assets/img/icons/flags/angola.svg'
  },
  {
    code: 'AI',
    name: 'Anguilla',
    icon: 'assets/img/icons/flags/anguilla.svg'
  },
  {
    code: 'AQ',
    name: 'Antarctica',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
    icon: 'assets/img/icons/flags/antigua-and-barbuda.svg'
  },
  {
    code: 'AR',
    name: 'Argentina',
    icon: 'assets/img/icons/flags/argentina.svg'
  },
  {
    code: 'AM',
    name: 'Armenia',
    icon: 'assets/img/icons/flags/armenia.svg'
  },
  {
    code: 'AW',
    name: 'Aruba',
    icon: 'assets/img/icons/flags/aruba.svg'
  },
  {
    code: 'AU',
    name: 'Australia',
    icon: 'assets/img/icons/flags/australia.svg'
  },
  {
    code: 'AT',
    name: 'Austria',
    icon: 'assets/img/icons/flags/austria.svg'
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    icon: 'assets/img/icons/flags/azerbaijan.svg'
  },
  {
    code: 'BS',
    name: 'Bahamas',
    icon: 'assets/img/icons/flags/bahamas.svg'
  },
  {
    code: 'BH',
    name: 'Bahrain',
    icon: 'assets/img/icons/flags/bahrain.svg'
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    icon: 'assets/img/icons/flags/bangladesh.svg'
  },
  {
    code: 'BB',
    name: 'Barbados',
    icon: 'assets/img/icons/flags/barbados.svg'
  },
  {
    code: 'BY',
    name: 'Belarus',
    icon: 'assets/img/icons/flags/belarus.svg'
  },
  {
    code: 'BE',
    name: 'Belgium',
    icon: 'assets/img/icons/flags/belgium.svg'
  },
  {
    code: 'BZ',
    name: 'Belize',
    icon: 'assets/img/icons/flags/belize.svg'
  },
  {
    code: 'BJ',
    name: 'Benin',
    icon: 'assets/img/icons/flags/benin.svg'
  },
  {
    code: 'BM',
    name: 'Bermuda',
    icon: 'assets/img/icons/flags/bermuda.svg'
  },
  {
    code: 'BT',
    name: 'Bhutan',
    icon: 'assets/img/icons/flags/bhutan.svg'
  },
  {
    code: 'BO',
    name: 'Bolivia, Plurinational State of',
    icon: 'assets/img/icons/flags/bolivia.svg'
  },
  {
    code: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
    icon: 'assets/img/icons/flags/bonaire.svg'
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
    icon: 'assets/img/icons/flags/bosnia-and-herzegovina.svg'
  },
  {
    code: 'BW',
    name: 'Botswana',
    icon: 'assets/img/icons/flags/botswana.svg'
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'BR',
    name: 'Brazil',
    icon: 'assets/img/icons/flags/brazil.svg'
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    icon: 'assets/img/icons/flags/british-indian-ocean-territory.svg'
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
    icon: 'assets/img/icons/flags/brunei.svg'
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    icon: 'assets/img/icons/flags/bulgaria.svg'
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
    icon: 'assets/img/icons/flags/burkina-faso.svg'
  },
  {
    code: 'BI',
    name: 'Burundi',
    icon: 'assets/img/icons/flags/burundi.svg'
  },
  {
    code: 'KH',
    name: 'Cambodia',
    icon: 'assets/img/icons/flags/cambodia.svg'
  },
  {
    code: 'CM',
    name: 'Cameroon',
    icon: 'assets/img/icons/flags/cameroon.svg'
  },
  {
    code: 'CA',
    name: 'Canada',
    icon: 'assets/img/icons/flags/canada.svg'
  },
  {
    code: 'CV',
    name: 'Cape Verde',
    icon: 'assets/img/icons/flags/cape-verde.svg'
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
    icon: 'assets/img/icons/flags/cayman-islands.svg'
  },
  {
    code: 'CF',
    name: 'Central African Republic',
    icon: 'assets/img/icons/flags/central-african-republic.svg'
  },
  {
    code: 'TD',
    name: 'Chad',
    icon: 'assets/img/icons/flags/chad.svg'
  },
  {
    code: 'CL',
    name: 'Chile',
    icon: 'assets/img/icons/flags/chile.svg'
  },
  {
    code: 'CN',
    name: 'China',
    icon: 'assets/img/icons/flags/china.svg'
  },
  {
    code: 'CX',
    name: 'Christmas Island',
    icon: 'assets/img/icons/flags/christmas-island.svg'
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
    icon: 'assets/img/icons/flags/cocos-island.svg'
  },
  {
    code: 'CO',
    name: 'Colombia',
    icon: 'assets/img/icons/flags/colombia.svg'
  },
  {
    code: 'KM',
    name: 'Comoros',
    icon: 'assets/img/icons/flags/comoros.svg'
  },
  {
    code: 'CG',
    name: 'Congo',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'CD',
    name: 'Congo, the Democratic Republic of the',
    icon: 'assets/img/icons/flags/republic-of-the-congo.svg'
  },
  {
    code: 'CK',
    name: 'Cook Islands',
    icon: 'assets/img/icons/flags/cook-islands.svg'
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    icon: 'assets/img/icons/flags/costa-rica.svg'
  },
  {
    code: 'CI',
    name: "Côte d'Ivoire",
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'HR',
    name: 'Croatia',
    icon: 'assets/img/icons/flags/croatia.svg'
  },
  {
    code: 'CU',
    name: 'Cuba',
    icon: 'assets/img/icons/flags/cuba.svg'
  },
  {
    code: 'CW',
    name: 'Curaçao',
    icon: 'assets/img/icons/flags/curacao.svg'
  },
  {
    code: 'CY',
    name: 'Cyprus',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    icon: 'assets/img/icons/flags/czech-republic.svg'
  },
  {
    code: 'DK',
    name: 'Denmark',
    icon: 'assets/img/icons/flags/denmark.svg'
  },
  {
    code: 'DJ',
    name: 'Djibouti',
    icon: 'assets/img/icons/flags/djibouti.svg'
  },
  {
    code: 'DM',
    name: 'Dominica',
    icon: 'assets/img/icons/flags/dominica.svg'
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    icon: 'assets/img/icons/flags/dominican-republic.svg'
  },
  {
    code: 'EC',
    name: 'Ecuador',
    icon: 'assets/img/icons/flags/ecuador.svg'
  },
  {
    code: 'EG',
    name: 'Egypt',
    icon: 'assets/img/icons/flags/egypt.svg'
  },
  {
    code: 'SV',
    name: 'El Salvador',
    icon: 'assets/img/icons/flags/el-salvador.svg'
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
    icon: 'assets/img/icons/flags/equatorial-guinea.svg'
  },
  {
    code: 'ER',
    name: 'Eritrea',
    icon: 'assets/img/icons/flags/eritrea.svg'
  },
  {
    code: 'EE',
    name: 'Estonia',
    icon: 'assets/img/icons/flags/estonia.svg'
  },
  {
    code: 'ET',
    name: 'Ethiopia',
    icon: 'assets/img/icons/flags/ethiopia.svg'
  },
  {
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
    icon: 'assets/img/icons/flags/falkland-islands.svg'
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'FJ',
    name: 'Fiji',
    icon: 'assets/img/icons/flags/fiji.svg'
  },
  {
    code: 'FI',
    name: 'Finland',
    icon: 'assets/img/icons/flags/finland.svg'
  },
  {
    code: 'FR',
    name: 'France',
    icon: 'assets/img/icons/flags/france.svg'
  },
  {
    code: 'GF',
    name: 'French Guiana',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'PF',
    name: 'French Polynesia',
    icon: 'assets/img/icons/flags/french-polynesia.svg'
  },
  {
    code: 'TF',
    name: 'French Southern Territories',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'GA',
    name: 'Gabon',
    icon: 'assets/img/icons/flags/gabon.svg'
  },
  {
    code: 'GM',
    name: 'Gambia',
    icon: 'assets/img/icons/flags/gambia.svg'
  },
  {
    code: 'GE',
    name: 'Georgia',
    icon: 'assets/img/icons/flags/georgia.svg'
  },
  {
    code: 'DE',
    name: 'Germany',
    icon: 'assets/img/icons/flags/germany.svg'
  },
  {
    code: 'GH',
    name: 'Ghana',
    icon: 'assets/img/icons/flags/ghana.svg'
  },
  {
    code: 'GI',
    name: 'Gibraltar',
    icon: 'assets/img/icons/flags/gibraltar.svg'
  },
  {
    code: 'GR',
    name: 'Greece',
    icon: 'assets/img/icons/flags/greece.svg'
  },
  {
    code: 'GL',
    name: 'Greenland',
    icon: 'assets/img/icons/flags/greenland.svg'
  },
  {
    code: 'GD',
    name: 'Grenada',
    icon: 'assets/img/icons/flags/grenada.svg'
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'GU',
    name: 'Guam',
    icon: 'assets/img/icons/flags/guam.svg'
  },
  {
    code: 'GT',
    name: 'Guatemala',
    icon: 'assets/img/icons/flags/guatemala.svg'
  },
  {
    code: 'GG',
    name: 'Guernsey',
    icon: 'assets/img/icons/flags/guernsey.svg'
  },
  {
    code: 'GN',
    name: 'Guinea',
    icon: 'assets/img/icons/flags/guinea.svg'
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
    icon: 'assets/img/icons/flags/guinea-bissau.svg'
  },
  {
    code: 'GY',
    name: 'Guyana',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'HT',
    name: 'Haiti',
    icon: 'assets/img/icons/flags/haiti.svg'
  },
  {
    code: 'VA',
    name: 'Holy Vatican City State',
    icon: 'assets/img/icons/flags/vatican-city.svg'
  },
  {
    code: 'HN',
    name: 'Honduras',
    icon: 'assets/img/icons/flags/honduras.svg'
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    icon: 'assets/img/icons/flags/hong-kong.svg'
  },
  {
    code: 'HU',
    name: 'Hungary',
    icon: 'assets/img/icons/flags/hungary.svg'
  },
  {
    code: 'IS',
    name: 'Iceland',
    icon: 'assets/img/icons/flags/iceland.svg'
  },
  {
    code: 'IN',
    name: 'India',
    icon: 'assets/img/icons/flags/india.svg'
  },
  {
    code: 'ID',
    name: 'Indonesia',
    icon: 'assets/img/icons/flags/indonesia.svg'
  },
  {
    code: 'IR',
    name: 'Iran, Islamic Republic of',
    icon: 'assets/img/icons/flags/iran.svg'
  },
  {
    code: 'IQ',
    name: 'Iraq',
    icon: 'assets/img/icons/flags/iraq.svg'
  },
  {
    code: 'IE',
    name: 'Ireland',
    icon: 'assets/img/icons/flags/ireland.svg'
  },
  {
    code: 'IM',
    name: 'Isle of Man',
    icon: 'assets/img/icons/flags/isle-of-man.svg'
  },
  {
    code: 'IL',
    name: 'Israel',
    icon: 'assets/img/icons/flags/israel.svg'
  },
  {
    code: 'IT',
    name: 'Italy',
    icon: 'assets/img/icons/flags/italy.svg'
  },
  {
    code: 'JM',
    name: 'Jamaica',
    icon: 'assets/img/icons/flags/jamaica.svg'
  },
  {
    code: 'JP',
    name: 'Japan',
    icon: 'assets/img/icons/flags/japan.svg'
  },
  {
    code: 'JE',
    name: 'Jersey',
    icon: 'assets/img/icons/flags/jersey.svg'
  },
  {
    code: 'JO',
    name: 'Jordan',
    icon: 'assets/img/icons/flags/jordan.svg'
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    icon: 'assets/img/icons/flags/kazakhstan.svg'
  },
  {
    code: 'KE',
    name: 'Kenya',
    icon: 'assets/img/icons/flags/kenya.svg'
  },
  {
    code: 'KI',
    name: 'Kiribati',
    icon: 'assets/img/icons/flags/kiribati.svg'
  },
  {
    code: 'KP',
    name: "Korea, Democratic People's Republic of",
    icon: 'assets/img/icons/flags/north-korea.svg'
  },
  {
    code: 'KR',
    name: 'Korea, Republic of',
    icon: 'assets/img/icons/flags/south-korea.svg'
  },
  {
    code: 'XK',
    name: 'Kosovo, Republic of',
    icon: 'assets/img/icons/flags/kosovo.svg'
  },
  {
    code: 'KW',
    name: 'Kuwait',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
    icon: 'assets/img/icons/flags/kyrgyzstan.svg'
  },
  {
    code: 'LV',
    name: 'Latvia',
    icon: 'assets/img/icons/flags/latvia.svg'
  },
  {
    code: 'LB',
    name: 'Lebanon',
    icon: 'assets/img/icons/flags/lebanon.svg'
  },
  {
    code: 'LS',
    name: 'Lesotho',
    icon: 'assets/img/icons/flags/lesotho.svg'
  },
  {
    code: 'LR',
    name: 'Liberia',
    icon: 'assets/img/icons/flags/liberia.svg'
  },
  {
    code: 'LY',
    name: 'Libya',
    icon: 'assets/img/icons/flags/libya.svg'
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    icon: 'assets/img/icons/flags/liechtenstein.svg'
  },
  {
    code: 'LT',
    name: 'Lithuania',
    icon: 'assets/img/icons/flags/lithuania.svg'
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    icon: 'assets/img/icons/flags/luxembourg.svg'
  },
  {
    code: 'MO',
    name: 'Macao',
    icon: 'assets/img/icons/flags/macao.svg'
  },
  {
    code: 'MK',
    name: 'Macedonia, North',
    icon: 'assets/img/icons/flags/republic-of-macedonia.svg'
  },
  {
    code: 'MG',
    name: 'Madagascar',
    icon: 'assets/img/icons/flags/madagascar.svg'
  },
  {
    code: 'MW',
    name: 'Malawi',
    icon: 'assets/img/icons/flags/malawi.svg'
  },
  {
    code: 'MY',
    name: 'Malaysia',
    icon: 'assets/img/icons/flags/malasya.svg'
  },
  {
    code: 'MV',
    name: 'Maldives',
    icon: 'assets/img/icons/flags/maldives.svg'
  },
  {
    code: 'ML',
    name: 'Mali',
    icon: 'assets/img/icons/flags/mali.svg'
  },
  {
    code: 'MT',
    name: 'Malta',
    icon: 'assets/img/icons/flags/malta.svg'
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
    icon: 'assets/img/icons/flags/marshall-island.svg'
  },
  {
    code: 'MQ',
    name: 'Martinique',
    icon: 'assets/img/icons/flags/martinique.svg'
  },
  {
    code: 'MR',
    name: 'Mauritania',
    icon: 'assets/img/icons/flags/mauritania.svg'
  },
  {
    code: 'MU',
    name: 'Mauritius',
    icon: 'assets/img/icons/flags/mauritius.svg'
  },
  {
    code: 'YT',
    name: 'Mayotte',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'MX',
    name: 'Mexico',
    icon: 'assets/img/icons/flags/mexico.svg'
  },
  {
    code: 'FM',
    name: 'Micronesia, Federated States of',
    icon: 'assets/img/icons/flags/micronesia.svg'
  },
  {
    code: 'MD',
    name: 'Moldova, Republic of',
    icon: 'assets/img/icons/flags/moldova.svg'
  },
  {
    code: 'MC',
    name: 'Monaco',
    icon: 'assets/img/icons/flags/monaco.svg'
  },
  {
    code: 'MN',
    name: 'Mongolia',
    icon: 'assets/img/icons/flags/mongolia.svg'
  },
  {
    code: 'ME',
    name: 'Montenegro',
    icon: 'assets/img/icons/flags/montenegro.svg'
  },
  {
    code: 'MS',
    name: 'Montserrat',
    icon: 'assets/img/icons/flags/montserrat.svg'
  },
  {
    code: 'MA',
    name: 'Morocco',
    icon: 'assets/img/icons/flags/morocco.svg'
  },
  {
    code: 'MZ',
    name: 'Mozambique',
    icon: 'assets/img/icons/flags/mozambique.svg'
  },
  {
    code: 'MM',
    name: 'Myanmar',
    icon: 'assets/img/icons/flags/myanmar.svg'
  },
  {
    code: 'NA',
    name: 'Namibia',
    icon: 'assets/img/icons/flags/namibia.svg'
  },
  {
    code: 'NR',
    name: 'Nauru',
    icon: 'assets/img/icons/flags/nauru.svg'
  },
  {
    code: 'NP',
    name: 'Nepal',
    icon: 'assets/img/icons/flags/nepal.svg'
  },
  {
    code: 'NL',
    name: 'Netherlands',
    icon: 'assets/img/icons/flags/netherlands.svg'
  },
  {
    code: 'NC',
    name: 'New Caledonia',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    icon: 'assets/img/icons/flags/new-zealand.svg'
  },
  {
    code: 'NI',
    name: 'Nicaragua',
    icon: 'assets/img/icons/flags/nicaragua.svg'
  },
  {
    code: 'NE',
    name: 'Niger',
    icon: 'assets/img/icons/flags/niger.svg'
  },
  {
    code: 'NG',
    name: 'Nigeria',
    icon: 'assets/img/icons/flags/nigeria.svg'
  },
  {
    code: 'NU',
    name: 'Niue',
    icon: 'assets/img/icons/flags/niue.svg'
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
    icon: 'assets/img/icons/flags/norfolk-island.svg'
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
    icon: 'assets/img/icons/flags/northern-marianas-islands.svg'
  },
  {
    code: 'NO',
    name: 'Norway',
    icon: 'assets/img/icons/flags/norway.svg'
  },
  {
    code: 'OM',
    name: 'Oman',
    icon: 'assets/img/icons/flags/oman.svg'
  },
  {
    code: 'PK',
    name: 'Pakistan',
    icon: 'assets/img/icons/flags/pakistan.svg'
  },
  {
    code: 'PW',
    name: 'Palau',
    icon: 'assets/img/icons/flags/palau.svg'
  },
  {
    code: 'PA',
    name: 'Panama',
    icon: 'assets/img/icons/flags/panama.svg'
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
    icon: 'assets/img/icons/flags/papua-new-guinea.svg'
  },
  {
    code: 'PY',
    name: 'Paraguay',
    icon: 'assets/img/icons/flags/paraguay.svg'
  },
  {
    code: 'PE',
    name: 'Peru',
    icon: 'assets/img/icons/flags/peru.svg'
  },
  {
    code: 'PH',
    name: 'Philippines',
    icon: 'assets/img/icons/flags/philippines.svg'
  },
  {
    code: 'PN',
    name: 'Pitcairn',
    icon: 'assets/img/icons/flags/pitcairn-islands.svg'
  },
  {
    code: 'PL',
    name: 'Poland',
    icon: 'assets/img/icons/flags/poland.svg'
  },
  {
    code: 'PT',
    name: 'Portugal',
    icon: 'assets/img/icons/flags/portugal.svg'
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
    icon: 'assets/img/icons/flags/puerto-rico.svg'
  },
  {
    code: 'QA',
    name: 'Qatar',
    icon: 'assets/img/icons/flags/qatar.svg'
  },
  {
    code: 'RE',
    name: 'Réunion',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'RO',
    name: 'Romania',
    icon: 'assets/img/icons/flags/romania.svg'
  },
  {
    code: 'RU',
    name: 'Russian Federation',
    icon: 'assets/img/icons/flags/russia.svg'
  },
  {
    code: 'RW',
    name: 'Rwanda',
    icon: 'assets/img/icons/flags/rwanda.svg'
  },
  {
    code: 'BL',
    name: 'Saint Barthélemy',
    icon: 'assets/img/icons/flags/st-barts.svg'
  },
  {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    icon: 'assets/img/icons/flags/saint-kitts-and-nevis.svg'
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
    icon: 'assets/img/icons/flags/st-lucia.svg'
  },
  {
    code: 'MF',
    name: 'Saint Martin (French part)',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    icon: 'assets/img/icons/flags/st-vincent-and-the-grenadines.svg'
  },
  {
    code: 'WS',
    name: 'Samoa',
    icon: 'assets/img/icons/flags/samoa.svg'
  },
  {
    code: 'SM',
    name: 'San Marino',
    icon: 'assets/img/icons/flags/san-marino.svg'
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
    icon: 'assets/img/icons/flags/sao-tome-and-prince.svg'
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    icon: 'assets/img/icons/flags/saudi-arabia.svg'
  },
  {
    code: 'SN',
    name: 'Senegal',
    icon: 'assets/img/icons/flags/senegal.svg'
  },
  {
    code: 'RS',
    name: 'Serbia',
    icon: 'assets/img/icons/flags/serbia.svg'
  },
  {
    code: 'SC',
    name: 'Seychelles',
    icon: 'assets/img/icons/flags/seychelles.svg'
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    icon: 'assets/img/icons/flags/sierra-leone.svg'
  },
  {
    code: 'SG',
    name: 'Singapore',
    icon: 'assets/img/icons/flags/singapore.svg'
  },
  {
    code: 'SX',
    name: 'Sint Maarten (Dutch part)',
    icon: 'assets/img/icons/flags/unknown.svg'
  },
  {
    code: 'SK',
    name: 'Slovakia',
    icon: 'assets/img/icons/flags/slovakia.svg'
  },
  {
    code: 'SI',
    name: 'Slovenia',
    icon: 'assets/img/icons/flags/slovenia.svg'
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    icon: 'assets/img/icons/flags/solomon-islands.svg'
  },
  {
    code: 'SO',
    name: 'Somalia',
    icon: 'assets/img/icons/flags/somalia.svg'
  },
  {
    code: 'ZA',
    name: 'South Africa',
    icon: 'assets/img/icons/flags/south-africa.svg'
  },
  {
    code: 'SS',
    name: 'South Sudan',
    icon: 'assets/img/icons/flags/south-sudan.svg'
  },
  {
    code: 'ES',
    name: 'Spain (España)',
    icon: 'assets/img/icons/flags/spain.svg'
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    icon: 'assets/img/icons/flags/sri-lanka.svg'
  },
  {
    code: 'SD',
    name: 'Sudan',
    icon: 'assets/img/icons/flags/sudan.svg'
  },
  {
    code: 'SR',
    name: 'Suriname',
    icon: 'assets/img/icons/flags/suriname.svg'
  },
  {
    code: 'SZ',
    name: 'Swaziland',
    icon: 'assets/img/icons/flags/swaziland.svg'
  },
  {
    code: 'SE',
    name: 'Sweden',
    icon: 'assets/img/icons/flags/sweden.svg'
  },
  {
    code: 'CH',
    name: 'Switzerland',
    icon: 'assets/img/icons/flags/switzerland.svg'
  },
  {
    code: 'SY',
    name: 'Syrian Arab Republic',
    icon: 'assets/img/icons/flags/syria.svg'
  },
  {
    code: 'TW',
    name: 'Taiwan, Province of China',
    icon: 'assets/img/icons/flags/taiwan.svg'
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    icon: 'assets/img/icons/flags/tajikistan.svg'
  },
  {
    code: 'TZ',
    name: 'Tanzania, United Republic of',
    icon: 'assets/img/icons/flags/tanzania.svg'
  },
  {
    code: 'TH',
    name: 'Thailand',
    icon: 'assets/img/icons/flags/thailand.svg'
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
    icon: 'assets/img/icons/flags/east-timor.svg'
  },
  {
    code: 'TG',
    name: 'Togo',
    icon: 'assets/img/icons/flags/togo.svg'
  },
  {
    code: 'TK',
    name: 'Tokelau',
    icon: 'assets/img/icons/flags/tokelau.svg'
  },
  {
    code: 'TO',
    name: 'Tonga',
    icon: 'assets/img/icons/flags/tonga.svg'
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
    icon: 'assets/img/icons/flags/trinidad-and-tobago.svg'
  },
  {
    code: 'TN',
    name: 'Tunisia',
    icon: 'assets/img/icons/flags/tunisia.svg'
  },
  {
    code: 'TR',
    name: 'Turkey',
    icon: 'assets/img/icons/flags/turkey.svg'
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    icon: 'assets/img/icons/flags/turkmenistan.svg'
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    icon: 'assets/img/icons/flags/turks-and-caicos.svg'
  },
  {
    code: 'TV',
    name: 'Tuvalu',
    icon: 'assets/img/icons/flags/tuvalu.svg'
  },
  {
    code: 'UG',
    name: 'Uganda',
    icon: 'assets/img/icons/flags/uganda.svg'
  },
  {
    code: 'UA',
    name: 'Ukraine',
    icon: 'assets/img/icons/flags/ukraine.svg'
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    icon: 'assets/img/icons/flags/united-arab-emirates.svg'
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    icon: 'assets/img/icons/flags/united-kingdom.svg'
  },
  {
    code: 'US',
    name: 'United States',
    icon: 'assets/img/icons/flags/united-states.svg'
  },
  {
    code: 'UY',
    name: 'Uruguay',
    icon: 'assets/img/icons/flags/uruguay.svg'
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    icon: 'assets/img/icons/flags/uzbekistan.svg'
  },
  {
    code: 'VU',
    name: 'Vanuatu',
    icon: 'assets/img/icons/flags/vanuatu.svg'
  },
  {
    code: 'VE',
    name: 'Venezuela, Bolivarian Republic of',
    icon: 'assets/img/icons/flags/venezuela.svg'
  },
  {
    code: 'VN',
    name: 'Viet Nam',
    icon: 'assets/img/icons/flags/vietnam.svg'
  },
  {
    code: 'VG',
    name: 'Virgin Islands, British',
    icon: 'assets/img/icons/flags/british-virgin-islands.svg'
  },
  {
    code: 'VI',
    name: 'Virgin Islands, U.S.',
    icon: 'assets/img/icons/flags/virgin-islands.svg'
  },
  {
    code: 'YE',
    name: 'Yemen',
    icon: 'assets/img/icons/flags/yemen.svg'
  },
  {
    code: 'ZM',
    name: 'Zambia',
    icon: 'assets/img/icons/flags/zambia.svg'
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
    icon: 'assets/img/icons/flags/zimbabwe.svg'
  }
];

export default countriesData;
