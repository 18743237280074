import React, { CSSProperties } from 'react';
import { withErrorBoundary } from '@sentry/react';

type RegistrationTextAreaT = {
  id: string;
  placeholder: string;
  value: string;
  onChange: Function;
  style?: CSSProperties;
  className: string;
  label: string;
  error: boolean;
  success?: boolean;
  helperText: string;
  required?: boolean;
};

export const borderColor = (error: boolean, success?: boolean) => {
  if (error) {
    return '#FE2142';
  }
  if (success) {
    return '#00DA8B';
  }

  return '#F0F1F7';
};

function RegistrationTextArea({
  id,
  placeholder,
  value,
  onChange,
  style,
  className,
  label,
  error,
  success,
  helperText,
  required
}: RegistrationTextAreaT) {
  return (
    <div
      className={`d-flex flex-col items-start justify-center ${className}`}
      style={style}
      data-testid="registration_text_area_wrapper">
      {label && (
        <p
          className="text-start uppercase"
          style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
          {label}
          {required && <strong style={{ color: '#FF4EA1' }}> * </strong>}
        </p>
      )}
      <textarea
        className="registration-text-area"
        placeholder={placeholder}
        autoComplete={id}
        id={id}
        name={id}
        data-testid="registration_text_area"
        value={value}
        onChange={(e) => onChange(e)}
        style={{
          width: '100%',
          backgroundColor: '#FCFDFE !important',
          borderColor: borderColor(error, success),
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '8px',
          transition: 'all 0.3s',
          padding: '10px 20px 10px 20px'
        }}
      />
      {(error || success) && helperText !== '' && (
        <p
          className="text-start"
          data-testid="registration_text_area_helper_text"
          style={{
            fontWeight: 400,
            color: error ? '#FE2142' : '#00DA8B',
            fontSize: 12
          }}>
          {' '}
          {helperText}{' '}
        </p>
      )}
    </div>
  );
}

export default withErrorBoundary(RegistrationTextArea, {
  fallback: <div>Failed to load text area component</div>
});
