import React, { useEffect, useState } from 'react';
import { withErrorBoundary } from '@sentry/react';
import { Box, Button, MenuItem, Modal } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import { CompanyState, NewMemberState } from '../types';
import RegistrationCheckbox from '../../../common/components/RegistrationCheckbox';
import {
  isValidString,
  parseDateDDMMYY,
  toBooleanOrFalse
} from '../../../common/utils/validations';
import RegistrationTextInput from '../../../common/components/RegistrationTextInput';
import MemberMissingInfo from './MemberMissingInfo';
import RegistrationSelect from '../../../common/components/RegistrationSelect';
import countriesData from '../../../common/utils/countriesData';

type CompanyMissingInfoProps = {
  company: CompanyState;
  handleCompanyCheckboxChange: Function;
  companyConfirmed: boolean;
  members: NewMemberState[];
  handleCompanyInputChange: Function;
  companyErrors: CompanyState;
  membersError: string;
  showMemberModal: boolean;
  handleShowMemberModal: Function;
  member: NewMemberState;
  handleCloseMemberModal: Function;
  handleMemberInputChange: Function;
  memberErrors: NewMemberState | null;
  handleMemberCheckbox: Function;
  handleValidateMemberFields: Function;
  handleAddUpdateMember: Function;
  handleIsRepresentativeCheckboxChange: Function;
  handleMemberCheckboxChange: Function;
  locale: string;
};

function CompanyMissingInfo({
  company,
  handleCompanyCheckboxChange,
  companyConfirmed,
  members,
  membersError,
  showMemberModal,
  handleShowMemberModal,
  member,
  handleCloseMemberModal,
  handleMemberInputChange,
  memberErrors,
  handleMemberCheckbox,
  handleCompanyInputChange,
  companyErrors,
  handleValidateMemberFields,
  handleAddUpdateMember,
  handleIsRepresentativeCheckboxChange,
  handleMemberCheckboxChange,
  locale
}: CompanyMissingInfoProps) {
  const [membersPage, setMembersPage] = useState<number>(1);
  const [membersPaginated, setMembersPaginated] = useState<NewMemberState[]>(members.slice(0, 3));

  const intl = useIntl();

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    handlePaginateMembers(membersPage);
  }, [member, members]);

  const membersOffset = 3;
  const totalPages = Math.ceil(members.length / membersOffset);

  const handlePaginateMembers = (page: number) => {
    setMembersPaginated(members.slice((page - 1) * membersOffset, page * membersOffset));
  };
  const handleMemberNextPage = () => {
    if (membersPage >= totalPages) {
      return;
    }
    let page: number = 1;
    setMembersPage((prevState: number) => {
      page = prevState + 1;
      return page;
    });
    handlePaginateMembers(page);
  };
  const handleMemberPreviousPage = () => {
    if (membersPage <= 1) {
      return;
    }
    let page: number = 1;
    setMembersPage((prevState: number) => {
      page = prevState - 1;
      return page;
    });
    handlePaginateMembers(page);
  };
  const memberRoles = (m: NewMemberState, separator: string) => {
    const roles = [];
    if (m.board_member) {
      roles.push(
        intl.formatMessage({
          id: 'board_member',
          defaultMessage: 'Board Member'
        })
      );
    }
    if (m.shareholder) {
      roles.push(
        intl.formatMessage({
          id: 'shareholder',
          defaultMessage: 'Shareholder'
        })
      );
    }
    if (m.is_representative) {
      roles.push(
        intl.formatMessage({
          id: 'representative',
          defaultMessage: 'Representative'
        })
      );
    }
    return roles.join(separator);
  };

  const currentMembers = () => {
    return (
      <>
        <div className="w-full md:min-h-[279px]">
          {membersPaginated.map((m: NewMemberState, index: number) => {
            return (
              <MemberMissingInfo
                key={index}
                member={m}
                members={members}
                handleShowMemberModal={handleShowMemberModal}
                memberRoles={memberRoles}
              />
            );
          })}
        </div>
        {members.length >= 3 && (
          <div className="flex w-full justify-end items-center mt-5">
            <p className="text-[14px] text-[#9FA2B4] mr-3">
              {' '}
              {`${membersPage * membersOffset - (membersOffset - 1)} - ${
                membersOffset * membersPage >= totalPages
                  ? members.length
                  : membersOffset * membersPage
              } ${intl.formatMessage({
                id: 'of',
                defaultMessage: 'of'
              })} ${members.length}`}{' '}
            </p>
            <div className="flex">
              <ArrowBackIos
                className={`hover:${membersPage === 1 ? 'cursor-default' : 'cursor-pointer'}`}
                onClick={() => handleMemberPreviousPage()}
                sx={{
                  margin: 0,
                  padding: 0,
                  fill: '#9FA2B4',
                  fontSize: '18px',
                  opacity: membersPage === 1 ? 0.5 : 1
                }}
              />
              <ArrowForwardIos
                className={`hover:${
                  membersPage === totalPages ? 'cursor-default' : 'cursor-pointer'
                }`}
                onClick={() => handleMemberNextPage()}
                sx={{
                  margin: 0,
                  padding: 0,
                  fill: '#9FA2B4',
                  fontSize: '18px',
                  opacity: membersPage >= totalPages ? 0.5 : 1
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const localizeDateFromEResidentId = (dateString: string) => {
    return new Date(
      parseInt(dateString.substr(4, 8), 10),
      parseInt(dateString.substr(2, 2), 10),
      parseInt(dateString.substr(0, 2), 10)
    );
  };

  const confirmCompany = () => {
    return (
      <Box
        className="flex flex-col items-center md:items-start mt-9"
        style={{ maxWidth: '650px', width: '100%' }}>
        <p className="registration-text-large-blue">
          <FormattedMessage id="company_found_title" defaultMessage="We've found your company!" />
        </p>
        <p className="mt-5 registration-text-medium-gray">
          <FormattedMessage
            id="company_found_subtitle"
            defaultMessage="Is this your company? Please confirm and proceed to the next step."
          />
        </p>
        <div className="w-full mt-5 rounded-[16px] p-[10px] md:pl-[54px] md:pr-[46px] md:pt-[28px] md:pb-[51px] text-left shadow-none md:shadow-[0px_8px_16px_rgba(0,0,0,0.16)]">
          <h1 className="text-[16px] md:text-[19px] font-bold text-[#4B506D]">
            {company.company_name}
          </h1>
          <ul className="list-disc list-inside text-[12px] md:text-[16px] font-normal text-[#9FA2B4]">
            <li className="mt-4">
              {' '}
              {`${intl.formatMessage({
                id: 'activity_description',
                defaultMessage: 'Activity description'
              })}: ${company.activity_description}`}{' '}
            </li>
            <li className="mt-5">
              {`${intl.formatMessage({
                id: 'registry_code',
                defaultMessage: 'Registry code'
              })}: ${company.registry_number}`}
            </li>
            <li className="mt-4">
              {' '}
              {`${intl.formatMessage({
                id: 'vat_number',
                defaultMessage: 'VAT number'
              })}: ${company.vat_number}`}
            </li>
            <li className="mt-4">{`${intl.formatMessage({
              id: 'registration_date',
              defaultMessage: 'Registration date'
            })}: ${intl.formatDate(company.date_registered as string, {
              dateStyle: 'long'
            })}`}</li>
            <li className="mt-4">{`${intl.formatMessage({
              id: 'members',
              defaultMessage: 'Members'
            })}: ${members.map((m) => `${m.first_name} ${m.last_name}`).join(', ')}`}</li>
          </ul>
        </div>
        <div className="flex mt-5">
          <RegistrationCheckbox
            id="company_confirmed"
            name="company_confirmed"
            inputStyles={{ borderRadius: '50px' }}
            checked={toBooleanOrFalse(company.company_confirmed)}
            onChange={handleCompanyCheckboxChange}
            label={
              <p className="text-[14px] font-medium text-[#9FA2B4]">
                <FormattedMessage
                  id="confirm_my_company"
                  defaultMessage="Yes, this is my company"
                />
              </p>
            }
          />
        </div>
      </Box>
    );
  };

  const companyInfo = () => {
    return (
      <Box
        className="flex flex-col items-center md:items-start mt-9"
        style={{ maxWidth: '650px', width: '100%' }}>
        <p className="registration-text-large-blue">
          <FormattedMessage id="company_information" defaultMessage="Company information" />
        </p>
        <p className="mt-5 registration-text-medium-gray">
          <FormattedMessage
            id="missing_company_information_subtitle"
            defaultMessage="Some data is missing in our database, so please complete the information for each company member."
          />
        </p>
        <div className="md:flex justify-between w-full">
          <RegistrationTextInput
            className="md:mr-3 w-full mt-5"
            style={{ width: '100%', marginTop: '26px' }}
            label={intl.formatMessage({
              id: 'corporate_phone',
              defaultMessage: 'CORPORATE PHONE'
            })}
            placeholder="+123456790"
            id="phone"
            value={company.phone}
            onChange={handleCompanyInputChange}
            error={!!companyErrors?.phone}
            helperText={companyErrors?.phone ? companyErrors.phone : ''}
            required
          />

          <RegistrationTextInput
            className="md:ml-3 w-full mt-5"
            style={{ width: '100%', marginTop: '26px' }}
            label={intl.formatMessage({
              id: 'corporate_email',
              defaultMessage: 'CORPORATE EMAIL'
            })}
            placeholder="email@waynecompany.com"
            id="email"
            value={company.email}
            onChange={handleCompanyInputChange}
            error={!!companyErrors?.email}
            helperText={companyErrors?.email ? companyErrors.email : ''}
            required
          />
        </div>
        <div className="mt-5 w-full">
          <p
            className="text-start uppercase"
            style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
            {' '}
            <FormattedMessage id="current_members" defaultMessage="Current members" />{' '}
          </p>
          {currentMembers()}
        </div>
        {isValidString(membersError) && (
          <p
            className="text-start mt-2"
            style={{
              fontWeight: 400,
              color: '#FE2142',
              fontSize: 12
            }}>
            {membersError}
          </p>
        )}
        <Modal
          open={showMemberModal}
          sx={{
            height: '100%',
            overflow: 'scroll',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Box
            className="h-max mt-5 mb-5"
            component="form"
            sx={{
              width: '80%',
              borderRadius: '12px',
              backgroundColor: 'white'
            }}>
            <div style={{ padding: '32px' }}>
              <p className="text-[#253292] font-bold text-[16px] md:text-[24px]">
                {`${member.first_name} ${member.last_name}`}
              </p>
              <div className="md:flex justify-between w-full">
                <RegistrationTextInput
                  className="md:mr-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'eresident_id',
                    defaultMessage: 'e-Resident code'
                  })}
                  placeholder="123456789"
                  id="eresident_id"
                  value={member.eresident_id}
                  onChange={handleMemberInputChange}
                  disabled
                />

                <RegistrationTextInput
                  className="md:ml-3 w-full mt-5"
                  label={`${intl.formatMessage({
                    id: 'share_capital',
                    defaultMessage: 'SHARE CAPITAL (€)'
                  })} (€)`}
                  placeholder=""
                  id="shareholder_contribution"
                  value={`${member.shareholder_contribution}€`}
                  onChange={handleMemberInputChange}
                  disabled
                />
              </div>
              <div className="md:flex justify-between w-full">
                <RegistrationTextInput
                  className="md:mr-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'role',
                    defaultMessage: 'Role'
                  })}
                  placeholder=""
                  id="Role"
                  value={memberRoles(member, ', ')}
                  onChange={handleMemberInputChange}
                  disabled
                />

                <RegistrationTextInput
                  className="md:ml-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'birthdate',
                    defaultMessage: 'Birth date'
                  })}
                  placeholder=""
                  id="birthdate"
                  value={
                    isValidString(member.birthdate)
                      ? intl.formatDate(
                          localizeDateFromEResidentId(parseDateDDMMYY(member.birthdate) as string),
                          { dateStyle: 'long' }
                        )
                      : ''
                  }
                  onChange={handleMemberInputChange}
                  disabled
                />
              </div>

              <div className="md:flex justify-between">
                <RegistrationTextInput
                  className="md:mr-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'email',
                    defaultMessage: 'email'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'enter_email',
                    defaultMessage: 'Enter Email'
                  })}
                  id="email"
                  value={member.email}
                  onChange={handleMemberInputChange}
                  error={!!memberErrors?.email}
                  helperText={memberErrors?.email ? memberErrors.email : ''}
                  required
                />
                <RegistrationTextInput
                  className="md:ml-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'phone',
                    defaultMessage: 'Phone'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'enter_phone',
                    defaultMessage: 'Enter phone number'
                  })}
                  id="phone"
                  value={member.phone}
                  onChange={handleMemberInputChange}
                  error={!!memberErrors?.phone}
                  helperText={memberErrors?.phone ? memberErrors.phone : ''}
                  required
                />
              </div>
              <div className="md:flex justify-between">
                <RegistrationTextInput
                  className="md:mr-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'address',
                    defaultMessage: 'Address'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'enter_address',
                    defaultMessage: 'Enter Address'
                  })}
                  id="address_street"
                  value={member.address_street}
                  onChange={handleMemberInputChange}
                  error={!!memberErrors?.address_street}
                  helperText={memberErrors?.address_street ? memberErrors.address_street : ''}
                  required
                />
                <RegistrationTextInput
                  className="md:ml-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'city',
                    defaultMessage: 'City'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'enter_city',
                    defaultMessage: 'Enter a city'
                  })}
                  id="address_city"
                  value={member.address_city}
                  onChange={handleMemberInputChange}
                  error={!!memberErrors?.address_city}
                  helperText={memberErrors?.address_city ? memberErrors.address_city : ''}
                  required
                />
              </div>
              <div className="md:flex justify-between">
                <RegistrationTextInput
                  className="md:mr-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'postcode',
                    defaultMessage: 'Postal code'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'enter_postal_code',
                    defaultMessage: 'Enter postal code'
                  })}
                  id="address_zip"
                  value={member.address_zip}
                  onChange={handleMemberInputChange}
                  error={!!memberErrors?.address_zip}
                  helperText={memberErrors?.address_zip ? memberErrors.address_zip : ''}
                  required
                />

                <RegistrationSelect
                  className="md:ml-3 w-full mt-5"
                  label={intl.formatMessage({
                    id: 'country',
                    defaultMessage: 'Country'
                  })}
                  id="address_country"
                  required
                  value={member.address_country}
                  onChange={handleMemberInputChange}>
                  {countriesData.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      <div className="flex items-center justify-start">
                        <RegistrationCheckbox
                          className="w-auto"
                          id={country.code}
                          name={country.code}
                          onChange={() => {}}
                          checked={member.address_country === country.code}
                        />
                        {country.name}
                      </div>
                    </MenuItem>
                  ))}
                </RegistrationSelect>
              </div>
              {/* Check list section */}
              <div className="md:flex justify-between">
                <div className="md:mr-3 w-full mt-5">
                  <RegistrationCheckbox
                    id="is_representative"
                    name="is_representative"
                    checked={member.is_representative}
                    onChange={handleIsRepresentativeCheckboxChange}
                    label={
                      <p
                        className="ml-2 md:ml-0"
                        style={{
                          fontWeight: 400,
                          fontSize: '12px',
                          color: '#9FA2B4',
                          fontFamily: 'TEEE'
                        }}>
                        <FormattedMessage
                          id="i_am_the_representative"
                          defaultMessage="This person will act as the representative of the company, the company registration invoice will be issued in his/her name."
                        />
                      </p>
                    }
                  />
                </div>
                <div className="md:ml-3 w-full mt-5">
                  <RegistrationCheckbox
                    id="pep"
                    name="pep"
                    checked={member.pep}
                    onChange={handleMemberCheckbox}
                    label={
                      <p
                        className="ml-0.5 md:ml-0"
                        style={{
                          fontWeight: 400,
                          fontSize: '12px',
                          color: '#9FA2B4',
                          fontFamily: 'TEEE'
                        }}>
                        <FormattedMessage
                          id="politically_exposed_1"
                          defaultMessage="This member is a politically exposed person"
                        />{' '}
                        <a
                          style={{ textDecoration: 'underline' }}
                          target="_blank"
                          rel="noreferrer"
                          href={
                            locale === 'es'
                              ? 'https://help.companio.co/es/article/que-es-una-persona-expuesta-politicamente-vmvtlr/'
                              : 'https://help.companio.co/en/article/whats-a-politically-exposed-person-znxlnb/'
                          }>
                          {' '}
                          <FormattedMessage
                            id="politically_exposed_2"
                            defaultMessage="politically exposed person"
                          />
                        </a>
                      </p>
                    }
                  />
                  {memberErrors?.pep && (
                    <p
                      className="text-start mt-2"
                      style={{
                        fontWeight: 400,
                        color: '#FE2142',
                        fontSize: 12
                      }}>
                      {' '}
                      {memberErrors.pep}{' '}
                    </p>
                  )}
                </div>
              </div>
              <div className="md:flex justify-between">
                <div className="w-full mt-5">
                  <RegistrationCheckbox
                    id="board_member"
                    data-testid="checkbox"
                    name="board_member"
                    checked={member.board_member}
                    onChange={handleMemberCheckboxChange}
                    label={
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: '12px',
                          color: '#9FA2B4',
                          fontFamily: 'TEEE'
                        }}>
                        <FormattedMessage
                          id="add_as_board_member"
                          defaultMessage="This person will be a board member"
                        />{' '}
                        <a
                          style={{ textDecoration: 'underline' }}
                          target="_blank"
                          rel="noreferrer"
                          href={`${
                            locale === 'es'
                              ? 'https://help.companio.co/es/article/cual-es-la-diferencia-entre-accionistas-y-miembros-de-la-junta-1rf3ea1/'
                              : 'https://help.companio.co/en/article/whats-the-difference-between-shareholders-and-board-members-yquerm/'
                          }`}>
                          <FormattedMessage
                            id="what_does_that_mean"
                            defaultMessage="What does that mean?"
                          />
                        </a>
                      </p>
                    }
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="flex items-center justify-end" style={{ padding: '36px' }}>
              <Button
                onClick={() => handleCloseMemberModal()}
                sx={{
                  maxWidth: '95px',
                  borderRadius: '31px',
                  width: '100%',
                  background: 'none',
                  color: '#9FA2B4',
                  fontFamily: 'TEEE',
                  fontSize: '14px',
                  fontWeight: 500,
                  textTransform: 'none',
                  marginRight: '10px',
                  ':hover': {
                    backgroundColor: 'none'
                  }
                }}>
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                disabled={handleValidateMemberFields()}
                onClick={() => {
                  handleAddUpdateMember();
                  handleCloseMemberModal();
                }}
                sx={{
                  maxWidth: '95px',
                  width: '100%',
                  borderRadius: '31px',
                  backgroundColor: '#FF4EA1',
                  fontFamily: 'TEEE',
                  color: 'white',
                  fontSize: '14px',
                  fontWeight: 500,
                  textTransform: 'none',
                  ':hover': {
                    backgroundColor: '#FF4EA1',
                    opacity: 0.7
                  },
                  ':disabled': {
                    color: 'white',
                    opacity: 0.3
                  }
                }}>
                <FormattedMessage id="accept" defaultMessage="Accept" />
              </Button>
            </div>
          </Box>
        </Modal>
      </Box>
    );
  };

  return companyConfirmed ? companyInfo() : confirmCompany();
}

export default withErrorBoundary(CompanyMissingInfo, {
  fallback: <div>Failed to load company missing information</div>
});
