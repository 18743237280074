import { withErrorBoundary } from '@sentry/react';
import { Route, Routes } from 'react-router-dom';
import React from 'react';
import NoMatch404Page from '../../common/components/NoMatch404Page';
import Register from './Register';

function RoutesES() {
  return (
    <Routes>
      <Route element={<Register />} index />
      <Route path="*" element={<NoMatch404Page />} />
    </Routes>
  );
}

export default withErrorBoundary(RoutesES, {
  fallback: <div>Failed to load</div>
});
