import { withErrorBoundary } from '@sentry/react';
import { Container } from '@mui/material';
import React from 'react';

function Register() {
  return (
    <>
      <div className="flex flex-col w-full px-4">
        <div className="flex justify-between">
          <div> </div>
        </div>
      </div>
      <Container maxWidth="md" className="mt-8">
        SPANISH!
      </Container>
    </>
  );
}

export default withErrorBoundary(Register, {
  fallback: <div>Failed to load</div>
});
