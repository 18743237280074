import { withStyles } from '@mui/styles';
import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import React, { CSSProperties, useLayoutEffect, useRef, useState } from 'react';
import { withErrorBoundary } from '@sentry/react';
import { borderColor } from './RegistrationTextInput';

const CustomFormControl = withStyles({
  root: {
    '& .MuiInputBase-root': {
      width: '100%',
      padding: 0
    },
    '& .MuiInputBase-root:after': {
      border: 'none !important',
      content: 'none !important',
      background: 'transparent !important'
    },
    '& .MuiInputBase-root:before': {
      outline: 'none !important',
      content: 'none !important',
      background: 'transparent !important'
    },
    '& .MuiSelect-select': {
      width: '100%',
      backgroundColor: '#FCFDFE',
      color: '#4B506D',
      border: '1px solid #F0F1F7',
      borderRadius: '8px !important',
      fontFamily: 'TEEE',
      fontWeight: 400,
      padding: '8.5px 14px',
      textAlign: 'left',
      '& div': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '& .registration-checkbox': {
          display: 'none'
        }
      }
    }
  }
})(FormControl);

function RegistrationSelect({
  id,
  value,
  onChange,
  label,
  style,
  className,
  children,
  required,
  error,
  success,
  warning,
  helperText
}: {
  id: string;
  value: string;
  onChange: Function;
  label: string;
  style?: CSSProperties;
  className: string;
  children: React.ReactNode;
  required?: boolean;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
  helperText?: string;
}) {
  const menuButtonRef = useRef<HTMLInputElement | null>(null);
  const [menuButtonWidth, setMenuButtonWidth] = useState<number | string>('inherit');

  useLayoutEffect(() => {
    if (menuButtonRef.current) {
      setMenuButtonWidth(menuButtonRef.current.offsetWidth);
    }
  });

  return (
    <div
      data-testid="registration_select_wrapper"
      className={`d-flex flex-col items-start justify-center ${className}`}
      style={style}>
      {label && (
        <p
          className="text-start uppercase"
          style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
          {label}
          {required && <strong style={{ color: '#FF4EA1' }}> * </strong>}
        </p>
      )}
      <CustomFormControl variant="standard" fullWidth size="medium">
        <Select
          ref={menuButtonRef}
          id={id}
          name={id}
          value={value}
          data-testid="registration_select"
          onChange={(e: SelectChangeEvent) => onChange(e)}
          inputProps={{
            'aria-label': 'Without label'
          }}
          MenuProps={{
            sx: {
              '& .MuiPaper-root': {
                width: menuButtonWidth,
                maxHeight: '250px',
                borderBox: '8px',
                padding: 0,
                '& ul': {
                  '& li': {
                    minHeight: '50px',
                    fontFamily: 'TEEE !important',
                    fontWeight: '500 !important',
                    color: '#253292',
                    fontSize: '14px',
                    whiteSpace: 'initial',
                    ':hover': {
                      backgroundColor: '#DDE2FF'
                    }
                  }
                }
              }
            }
          }}>
          {children}
        </Select>
      </CustomFormControl>
      {(error || success || warning) && helperText !== '' && (
        <p
          className="text-start"
          data-testid="registration_text_input_helper_text"
          style={{
            fontWeight: 400,
            color: borderColor(error, success, warning),
            fontSize: 12,
            marginTop: '0.7rem'
          }}>
          {' '}
          {helperText}{' '}
        </p>
      )}
    </div>
  );
}

export default withErrorBoundary(RegistrationSelect, {
  fallback: <div>Failed to load select component</div>
});
