import { NewMemberState } from '../../features/main/types';

export function isValidDate(dateOrString: Date | string | null) {
  if (dateOrString === null) {
    return false;
  }
  if (!dateOrString) {
    return false;
  }
  if (
    dateOrString instanceof Date ||
    Object.prototype.toString.call(dateOrString) === '[object Date]'
  ) {
    // Date object
    return true;
  }
  if (typeof dateOrString === 'string') {
    const yyyymmddhhmm = yyyymmddAndHHMMStringToDate(dateOrString);
    if (yyyymmddhhmm || Object.prototype.toString.call(yyyymmddhhmm) === '[object Date]') {
      return true;
    }
    // YYYY-MM-DD
    const dateFromString = yyyymmddhhmmssStringToDate(dateOrString);
    if (dateFromString || Object.prototype.toString.call(dateFromString) === '[object Date]') {
      return true;
    }
    // last chance
    // @ts-ignore
    const iso8601Date = new Date(dateOrString);
    if (iso8601Date || Object.prototype.toString.call(iso8601Date) === '[object Date]') {
      return true;
    }
  }
  return false;
}

export function dateToDDMMYYYYString(date: Date | string | null) {
  if (!date) {
    return null;
  }
  let safeDate: Date | string = date;
  if (typeof date === 'string') {
    safeDate = new Date(date);
  }
  if (!isValidDate(date)) {
    return null;
  }
  const day = `0${(safeDate as Date).getDate()}`.slice(-2);
  const month = `0${(safeDate as Date).getMonth() + 1}`.slice(-2);
  const year = (safeDate as Date).getFullYear();

  return day + month + year;
}

export function yyyymmddAndHHMMStringToDate(dateString: string) {
  const arr = dateString.split(/-|\s|:/); // split string and create array.
  if (Array.isArray(arr) && arr.length === 5) {
    const year = arr[0];
    const month = `0${arr[1]}`.slice(-2);
    const day = `0${arr[2]}`.slice(-2);
    const hour = `0${arr[3]}`.slice(-2);
    const minute = `0${arr[4]}`.slice(-2);
    const date = new Date(`${year}-${month}-${day}T${hour}:${minute}:00+00:00`);
    return Number.isNaN(date.getTime()) ? null : date;
  }
  return null;
}

export function yyyymmddhhmmssStringToDate(dateString: string): Date | null {
  const arr = dateString.split(/-|\s|:/); // split string and create array.
  if (!Array.isArray(arr) || arr.length !== 6) {
    return null;
  }
  const year = arr[0];
  const month = `0${arr[1]}`.slice(-2);
  const day = `0${arr[2]}`.slice(-2);
  const hour = `0${arr[3]}`.slice(-2);
  const minute = `0${arr[4]}`.slice(-2);
  const second = `0${arr[5]}`.slice(-2);
  const date = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`);
  return Number.isNaN(date.getTime()) ? null : date;
}

export function isValidString(o?: string | object | number | null) {
  if (o === null) {
    return false;
  }
  if (typeof o === 'string' || (typeof o === 'object' && o.constructor === String)) {
    return o.trim().length > 0;
  }
  return false;
}

export function isValidInteger(value?: string | number, strictType?: boolean) {
  if (value === null) {
    return false;
  }
  if (!value) {
    return false;
  }
  if (Number.isNaN(parseInt(value as string, 10))) {
    return false;
  }
  // @ts-ignore
  return !(strictType && Math.floor(value) !== value);
}

export function isValidE164PhoneNumber(
  number?: string | number,
  allowWhitespacesAndHyphens?: boolean
) {
  if (!isValidString(number) && !isValidUnsignedInteger(number)) {
    return false;
  }
  let safeNumber = number;
  if (isValidUnsignedInteger(safeNumber)) {
    safeNumber += '';
  }
  if (allowWhitespacesAndHyphens) {
    safeNumber = String(safeNumber).replace(/[\s-]/g, '');
  }
  const re = /^\+[1-9]\d{1,14}$/;
  return re.test(String(safeNumber));
}

export function isValidNumber(value?: number) {
  if (value === null) {
    return false;
  }
  if (!value) {
    return false;
  }
  return !Number.isNaN(value);
}

export function isValidUnsignedInteger(value?: string | number, strictType?: boolean) {
  if (!value) {
    return false;
  }
  if (isValidInteger(value, strictType)) {
    return value >= 0;
  }
  return false;
}

export function isValidEmail(email: string) {
  if (email === null) {
    return false;
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isMicrosoftEmail(email: string) {
  const re = /[a-zA-Z0-9_\-.+]+@(live|hotmail|passport|outlook|msn)\./;
  return re.test(email);
}

// eslint-disable-next-line consistent-return
export function isValidBoolean(value: any) {
  if (value === null) {
    return false;
  }
  if (value === true || value === false) {
    return true;
  }
  if (isValidString(value)) {
    if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
      return true;
    }
    if (value.toLowerCase() === 't' || value.toLowerCase() === 'f') {
      return true;
    }
    if (value.toLowerCase() === '1' || value.toLocaleLowerCase === '0') {
      return true;
    }
  } else if (value === 1 || value === 0) {
    return true;
  } else {
    return false;
  }
}

export function toBooleanOrFalse(value: any) {
  if (isValidBoolean(value)) {
    if (value === true) {
      return true;
    }
    if (value === 1) {
      return true;
    }
    if (isValidString(value)) {
      const valueLc = value.toLowerCase();
      if (valueLc === 'true' || valueLc === 't' || valueLc === '1') {
        return true;
      }
    }
  }
  return false;
}

// e-residency validations
const kEResidencyLibraryMonthLengths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const kEResidencyLibraryLegalAge = 18;

export function isValidEresidencyCode(code: string) {
  if (!checkValidString(code)) {
    return false;
  }
  if (!checkValidUnsignedInteger(code)) {
    return false;
  }
  if (code.length !== 11) {
    return false;
  }
  return checksumValidForIsikukood(code);
}

export function isValidEresidencyCodeOrBirthDateDDMMYYYY(
  code: string,
  checkLegalAgeForCompany?: boolean
) {
  if (!checkValidString(code)) {
    return false;
  }
  if (!checkValidUnsignedInteger(code)) {
    return false;
  }
  if (code.length === 11) {
    return checksumValidForIsikukood(code);
  }
  if (code.length === 8) {
    let day: string | number = code.substr(0, 2);
    let month: string | number = code.substr(2, 2);
    let year: string | number = code.substr(4, 4);

    day = parseInt(day, 10);
    month = parseInt(month, 10);
    year = parseInt(year, 10);

    // check month
    if (!(month > 0 && month < 13)) {
      return false;
    }
    // check year
    if (year < 1900) {
      return false;
    }

    // Adjust for leap years
    if ((year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) && month === 2) {
      return !(day < 1 || day > 29);
    }
    if (day < 1 || day > kEResidencyLibraryMonthLengths[month - 1]) {
      return false;
    }

    // underage?
    if (checkLegalAgeForCompany && new Date().getFullYear() - year < kEResidencyLibraryLegalAge) {
      return false;
    }

    return true;
  }
  return false;
}

export function checkValidString(o?: string | object) {
  if (o === null) {
    return false;
  }
  if (!o) {
    return false;
  }
  if (typeof o === 'string' || (typeof o === 'object' && o.constructor === String)) {
    return o.length > 0;
  }
  return false;
}

export function checkValidUnsignedInteger(value?: number | string) {
  if (value === null) {
    return false;
  }
  if (!value) {
    return false;
  }
  if (checkValidInteger(value)) {
    return value >= 0;
  }
  return false;
}

export function checkValidInteger(value?: number | string) {
  if (value === null) {
    return false;
  }
  if (!value) {
    return false;
  }
  return !Number.isNaN(Number(value));
}

export function checksumValidForIsikukood(code: string) {
  const control = code.charAt(10);
  const checksum = calculateChecksumForIsikukood(code);

  return Number(control) === checksum;
}

export function calculateChecksumForIsikukood(code: string) {
  const multiplier1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
  const multiplier2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];

  let mod: number = 0;
  let total: number = 0;

  /* Do first run. */
  for (let i = 0; i < 10; i += 1) {
    // @ts-ignore
    total += code.charAt(i) * multiplier1[i];
  }
  mod = total % 11;

  /* If modulus is ten we need second run. */
  total = 0;
  if (mod === 10) {
    for (let i = 0; i < 10; i += 1) {
      // @ts-ignore
      total += code.charAt(i) * multiplier2[i];
    }
    mod = total % 11;

    /* If modulus is still ten revert to 0. */
    if (mod === 10) {
      mod = 0;
    }
  }

  return mod;
}

export function birthDateStringFromIsikukood(code: string) {
  if (isValidEresidencyCode(code)) {
    const year = code.substr(1, 2);
    const month = code.substr(3, 2);
    const day = code.substr(5, 2);
    return `${day}${month}${year}`;
  }
  return null;
}

export function uniqueIdForPerson(person?: NewMemberState) {
  if (!person) {
    return null;
  }
  let dateString = null;
  if (
    Object.prototype.hasOwnProperty.call(person, 'eresident_id') &&
    isValidEresidencyCode(person.eresident_id)
  ) {
    dateString = birthDateStringFromIsikukood(person.eresident_id);
  } else if (person.birthdate && isValidEresidencyCodeOrBirthDateDDMMYYYY(person.birthdate)) {
    const day = person.birthdate.substr(0, 2);
    const month = person.birthdate.substr(2, 2);
    const year = person.birthdate.substr(6, 2);
    dateString = `${day}${month}${year}`;
  }
  let result = `${person.first_name.toLowerCase()}_${person.last_name.toLowerCase()}_${dateString}`;
  result = result.trim().replace(/\s+/gi, '_');
  return result;
}

export function parseDateDDMMYY(date: string | null | undefined) {
  if (!isValidString(date) || date?.length !== 6) {
    return date;
  }

  const now = new Date();
  const currentTwoCharYear = parseInt(new Date().getFullYear().toString().substr(2, 2), 10);

  const thisCentury = now.getFullYear() - currentTwoCharYear;
  const previousCentury = thisCentury - 100;

  const day = date.substr(0, 2);
  const month = date.substr(2, 2);

  let year = parseInt(date.substr(4, 2), 10);
  year = year > currentTwoCharYear ? previousCentury + year : thisCentury + year;

  return day + month + year;
}
