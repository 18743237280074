import React from 'react';
import { Box } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import { FormattedMessage } from 'react-intl';
import RegistrationCheckbox from '../../../common/components/RegistrationCheckbox';
import { CompanyTerms } from '../types';

type CompanyTermsConfirmationProps = {
  handleCompanyTermsCheckboxChange: Function;
  companyTermsCheckboxes: CompanyTerms;
};

function CompanyTermsConfirmation({
  handleCompanyTermsCheckboxChange,
  companyTermsCheckboxes
}: CompanyTermsConfirmationProps) {
  return (
    <Box
      className="flex flex-col items-center md:items-start mt-9"
      style={{ maxWidth: '650px', width: '100%' }}>
      <p className="registration-text-large-blue">
        <FormattedMessage id="before_continuing" defaultMessage="Before continuing..." />
      </p>
      <p className="mt-5 registration-text-medium-gray">
        <FormattedMessage
          id="before_continuing_subtitle"
          defaultMessage="We need you to carefully read the following options and check before proceeding with the process."
        />
      </p>
      <div className="justify-between">
        <p
          className="mt-5 registration-text-medium-gray"
          style={{
            fontWeight: 500,
            color: '#4B506D'
          }}>
          <FormattedMessage
            id="i_confirm"
            defaultMessage="I confirm that my business will NOT..."
          />
        </p>
        <div className="items-center md:mr-3 w-full mt-5 grid gap-6">
          <RegistrationCheckbox
            id="payForDrinks"
            name="payForDrinks"
            checked={companyTermsCheckboxes.payForDrinks}
            onChange={handleCompanyTermsCheckboxChange}
            label={
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#9FA2B4'
                }}>
                <FormattedMessage
                  id="pay_for_drinks_check"
                  defaultMessage="Pay for drinks or meals for myself or my clients."
                />
              </p>
            }
          />
          <RegistrationCheckbox
            id="issueNewCrypto"
            name="issueNewCrypto"
            checked={companyTermsCheckboxes.issueNewCrypto}
            onChange={handleCompanyTermsCheckboxChange}
            label={
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#9FA2B4'
                }}
                className="text-left">
                <FormattedMessage
                  id="issue_new_crypto_check"
                  defaultMessage="Issue new crypto tokens, cryptocurrencies, ICOs/IPOs, collect or trade NFTs, do crypto mining, or engage in direct currency exchange between fiat and cryptocurrencies without using an exchange platform."
                />
              </p>
            }
          />
          <RegistrationCheckbox
            id="conductCashTransactions"
            name="conductCashTransactions"
            checked={companyTermsCheckboxes.conductCashTransactions}
            onChange={handleCompanyTermsCheckboxChange}
            label={
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#9FA2B4'
                }}
                className="text-left">
                <FormattedMessage
                  id="conduct_cash_transactions_check"
                  defaultMessage="Conduct cash transactions or withdraw money from an ATM using corporate funds."
                />
              </p>
            }
          />
          <RegistrationCheckbox
            id="shipPhysicalGoods"
            name="shipPhysicalGoods"
            checked={companyTermsCheckboxes.shipPhysicalGoods}
            onChange={handleCompanyTermsCheckboxChange}
            label={
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#9FA2B4'
                }}
                className="text-left">
                <FormattedMessage
                  id="ship_physical_check"
                  defaultMessage="Buy, store, manage, or ship physical goods directly to clients, or ship physical goods first to my country and then to final customers. If my business is an online shop or e-commerce, my providers or logistics partners will store, handle, and ship the physical goods."
                />
              </p>
            }
          />
          <RegistrationCheckbox
            id="investmentActivities"
            name="investmentActivities"
            checked={companyTermsCheckboxes.investmentActivities}
            onChange={handleCompanyTermsCheckboxChange}
            label={
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#9FA2B4'
                }}
                className="text-left">
                <FormattedMessage
                  id="investment_activities_check"
                  defaultMessage="Engage in financial or investment activities, loans or borrow money between the business and company members or any third parties, in either direction."
                />
              </p>
            }
          />
          <RegistrationCheckbox
            id="ownRealEstate"
            name="ownRealEstate"
            checked={companyTermsCheckboxes.ownRealEstate}
            onChange={handleCompanyTermsCheckboxChange}
            label={
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#9FA2B4'
                }}
                className="text-left">
                <FormattedMessage
                  id="on_real_estate_check"
                  defaultMessage="Own real estate of any kind."
                />
              </p>
            }
          />
          <RegistrationCheckbox
            id="makeDonations"
            name="makeDonations"
            checked={companyTermsCheckboxes.makeDonations}
            onChange={handleCompanyTermsCheckboxChange}
            label={
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#9FA2B4'
                }}
                className="text-left">
                <FormattedMessage id="make_donations" defaultMessage="Make donations." />
              </p>
            }
          />
        </div>
      </div>
    </Box>
  );
}

export default withErrorBoundary(CompanyTermsConfirmation, {
  fallback: <div>Failed to load company info form</div>
});
