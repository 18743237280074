import React, { useState } from 'react';
import { withErrorBoundary } from '@sentry/react';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NewMemberState } from '../types';
import {
  isValidE164PhoneNumber,
  isValidEmail,
  isValidString
} from '../../../common/utils/validations';

type CompanyMissingInfoProps = {
  member: NewMemberState;
  members: NewMemberState[];
  handleShowMemberModal: Function;
  memberRoles: Function;
};

function MemberMissingInfo({
  member,
  members,
  handleShowMemberModal,
  memberRoles
}: CompanyMissingInfoProps) {
  const [anchorElMemberMenu, setAnchorElMemberMenu] = useState<null | HTMLElement>(null);
  const memberMenuOpen: boolean = Boolean(anchorElMemberMenu);
  const intl = useIntl();

  const rootMemberIndex = members.findIndex(
    (mem: NewMemberState) => mem.eresident_id === member.eresident_id
  );

  const isMemberValid =
    isValidEmail(member.email) &&
    isValidE164PhoneNumber(member.phone) &&
    isValidString(member.address_street) &&
    isValidString(member.address_country) &&
    isValidString(member.address_city) &&
    isValidString(member.address_zip);

  return (
    <div key={rootMemberIndex}>
      <div className="flex items-center justify-between w-full py-[10px] md:py-[24px]">
        <div className="flex items-center w-1/2">
          <img
            className="hidden sm:block mr-[24px]"
            src="/assets/icons/rounded_user.svg"
            alt="user"
          />
          <div className="text-left">
            <p className="font-medium text-[#253292] text-[14px] text-left">{`${member.first_name} ${member.last_name}`}</p>
            <p className="text-[12px] font-normal text-[#C5C7CD] text-left">
              {memberRoles(member, ', ')}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-end w-1/2">
          <div className="flex items-center sm:w-[192.5px]">
            <Tooltip
              disableHoverListener
              title={intl.formatMessage({
                id: isMemberValid ? 'member_info_complete' : 'member_missing_info',
                defaultMessage: isMemberValid
                  ? 'Information is complete'
                  : 'There is missing information'
              })}>
              <img
                src={isMemberValid ? '/assets/icons/checked_green.svg' : '/assets/icons/danger.svg'}
                alt="alert_icon"
                width={23}
              />
            </Tooltip>
            <p
              className="hidden sm:block text-[12px] ml-2 text-left"
              style={{ color: isMemberValid ? '#00DA8B' : '#FE2142' }}>
              {' '}
              <FormattedMessage
                id={isMemberValid ? 'member_info_complete' : 'member_missing_info'}
                defaultMessage={
                  isMemberValid ? 'Information is complete' : 'There is missing information'
                }
              />{' '}
            </p>
          </div>
          <div>
            <Button
              id={`member-menu-button-${rootMemberIndex}`}
              aria-controls={memberMenuOpen ? `member-menu-${rootMemberIndex}` : undefined}
              aria-haspopup="true"
              aria-expanded={memberMenuOpen ? 'true' : undefined}
              data-testid="member_menu_button"
              onClick={(e) => {
                setAnchorElMemberMenu(e.currentTarget as HTMLElement);
              }}>
              <MoreVertIcon style={{ color: '#C5C7CD' }} />
            </Button>
            <Menu
              id={`member-menu-${rootMemberIndex}`}
              anchorEl={anchorElMemberMenu}
              open={memberMenuOpen}
              data-testid="member_menu_close"
              onClose={() => setAnchorElMemberMenu(null)}
              MenuListProps={{
                'aria-labelledby': `member-menu-button-${rootMemberIndex}`,
                sx: {
                  borderRadius: '8px'
                }
              }}>
              <MenuItem
                sx={{
                  fontFamily: 'TEEE',
                  fontSize: '14px',
                  color: '#9FA2B4',
                  fontWeight: 400
                }}
                id="Update"
                onClick={(e) => {
                  setAnchorElMemberMenu(null);
                  handleShowMemberModal(e, rootMemberIndex);
                }}>
                <FormattedMessage id="add_info" defaultMessage="Add information" />
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default withErrorBoundary(MemberMissingInfo, {
  fallback: <div>Failed to load member missing info </div>
});
