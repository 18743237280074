import React from 'react';
import { withErrorBoundary } from '@sentry/react';
import './styles.css';

function RegistrationCheckbox({
  id,
  name,
  onChange,
  checked,
  disabled,
  className,
  containerStyles,
  inputStyles,
  label,
  required
}: {
  id: string;
  name: string;
  onChange: Function;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  containerStyles?: object;
  inputStyles?: any;
  label?: React.ReactNode;
  required?: boolean;
}) {
  // eslint-disable-next-line no-param-reassign
  return (
    <div
      className={
        className
          ? `registration-checkbox flex items-center w-full h-100 ${className}`
          : 'registration-checkbox flex items-center w-full h-100'
      }
      style={containerStyles}>
      <input
        id={id}
        name={name}
        type="checkbox"
        className={checked ? 'checked' : 'unchecked'}
        disabled={disabled}
        onChange={(e: any) => onChange(e)}
        style={inputStyles}
      />
      <div className="flex" style={{ marginLeft: '16px' }}>
        {label && label}
        {required && <strong>*</strong>}
      </div>
    </div>
  );
}

export default withErrorBoundary(RegistrationCheckbox, {
  fallback: <div>Failed to load checkbox component</div>
});
