import React from 'react';
import { withErrorBoundary } from '@sentry/react';
import { Route, Routes } from 'react-router-dom';
import Register from './Register';
import RegistrationPayment from './RegistrationPayment/index';
import Steps from './Steps';

import RegisterThankYou from './RegisterThankYou';
import NoMatch404Page from '../../common/components/NoMatch404Page';
import { Localization } from './types';
import AuthValidate from './AuthValidate';

function RoutesMain({ setLocale, locale }: Localization) {
  return (
    <Routes>
      <Route element={<Register setLocale={setLocale} locale={locale} />} index />
      <Route
        element={<Steps setLocale={setLocale} locale={locale} />}
        path="register/steps/:token/:email/"
      />

      <Route element={<AuthValidate />}>
        <Route element={<RegistrationPayment locale={locale as string} />} path="payment" />
        <Route
          element={<RegisterThankYou setLocale={setLocale} locale={locale} />}
          path="thank_you"
        />
      </Route>

      <Route element={<Register setLocale={setLocale} locale={locale} />} path="/*" />
      <Route path="*" element={<NoMatch404Page />} />
    </Routes>
  );
}

export default withErrorBoundary(RoutesMain, { fallback: <div>Failed to load</div> });
