import { Button, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState } from 'react';
import { withErrorBoundary } from '@sentry/react';
import { FormattedMessage } from 'react-intl';
import { CompanyState, NewMemberState } from '../types';
import RegistrationCapitalContributionSlider from '../../../common/components/RegistrationCapitalContributionSlider';

type CompanyInfoFormProps = {
  member: NewMemberState;
  members: NewMemberState[];
  index: number;
  handleRemoveMemberByIndex: Function;
  handleShowMemberModal: Function;
  company: CompanyState;
  handleUpdateShareCapitalContribution: Function;
};

function Member({
  member,
  members,
  index,
  handleRemoveMemberByIndex,
  handleShowMemberModal,
  company,
  handleUpdateShareCapitalContribution
}: CompanyInfoFormProps) {
  const [anchorElMemberMenu, setAnchorElMemberMenu] = useState<null | HTMLElement>(null);
  const memberMenuOpen: boolean = Boolean(anchorElMemberMenu);
  const shareholders = members.filter((m) => m.shareholder);

  return (
    <div key={index}>
      <div>
        <div className="flex items-center justify-between mt-3 mb-3">
          <div className="flex items-center">
            <img
              src="/assets/icons/rounded_user.svg"
              alt={`avatar-${index}`}
              style={{
                maxWidth: '44px',
                maxHeight: '44px',
                width: '100%',
                height: '100%'
              }}
            />
            <div id="text" className="text-left" style={{ marginLeft: '16px' }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  color: '#253292'
                }}>
                {`${member.first_name} ${member.last_name}`}
              </p>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#C5C7CD'
                }}>
                {member.email}
              </p>
            </div>
          </div>
          <div>
            <Button
              id={`member-menu-button-${index}`}
              aria-controls={memberMenuOpen ? `member-menu-${index}` : undefined}
              aria-haspopup="true"
              aria-expanded={memberMenuOpen ? 'true' : undefined}
              data-testid="member_menu_button"
              onClick={(e) => setAnchorElMemberMenu(e.currentTarget as HTMLElement)}>
              <MoreVertIcon style={{ color: '#C5C7CD' }} />
            </Button>
            <Menu
              id={`member-menu-${index}`}
              anchorEl={anchorElMemberMenu}
              open={memberMenuOpen}
              data-testid="member_menu_close"
              onClose={() => {
                setAnchorElMemberMenu(null);
              }}
              MenuListProps={{
                'aria-labelledby': `member-menu-button-${index}`,
                sx: {
                  borderRadius: '8px'
                }
              }}>
              <MenuItem
                sx={{
                  fontFamily: 'TEEE',
                  fontSize: '16px',
                  color: '#253292',
                  fontWeight: 400
                }}
                id="Update"
                onClick={(e) => {
                  setAnchorElMemberMenu(null);
                  handleShowMemberModal(e, index);
                }}>
                <FormattedMessage id="update" defaultMessage="Update" />
              </MenuItem>
              <hr />
              <MenuItem
                disabled={members.length > 1 && index === 0}
                sx={{
                  fontFamily: 'TEEE',
                  fontSize: '16px',
                  color: '#FE2142',
                  fontWeight: 400
                }}
                onClick={() => {
                  setAnchorElMemberMenu(null);
                  handleRemoveMemberByIndex(index);
                }}>
                <FormattedMessage id="delete" defaultMessage="Delete" />
              </MenuItem>
            </Menu>
          </div>
        </div>
        <RegistrationCapitalContributionSlider
          sliderId={index.toString()}
          value={member.shareholder ? parseFloat(member.shareholder_contribution || '0') : 0}
          color="#253292"
          maxValue={parseFloat(company.share_capital || '0')}
          onChange={(e: Event) => {
            handleUpdateShareCapitalContribution(e);
          }}
          percentage={
            company.share_capital
              ? (
                  (parseFloat(member.shareholder_contribution || '0') /
                    parseFloat(company.share_capital)) *
                  100
                ).toFixed(2)
              : '0'
          }
          disabled={shareholders.length <= 1 || !member.shareholder}
        />
      </div>
    </div>
  );
}

export default withErrorBoundary(Member, {
  fallback: <div> Failed to load members </div>
});
