type Reason = {
  id: string;
  value: string;
};

const switchingReason: Reason[] = [
  { id: 'switch_reason_companio_cool', value: 'companio_cool' },
  { id: 'switch_reason_previous_provider_expensive', value: 'previous_provider_expensive' },
  { id: 'switch_reason_previous_provider_bank', value: 'previous_provider_bank' },
  { id: 'switch_reason_previous_provider_activity', value: 'previous_provider_activity' },
  { id: 'switch_reason_previous_provider_members', value: 'previous_provider_members' },
  { id: 'switch_reason_previous_provider_employees', value: 'previous_provider_employees' },
  { id: 'switch_reason_previous_provider_accounting', value: 'previous_provider_accounting' },
  { id: 'switch_reason_previous_provider_problem', value: 'previous_provider_proble' },
  { id: 'switch_reason_like_you', value: 'like_you' },
  { id: 'switch_reason_other', value: 'other' }
];

export default switchingReason;
