import Cookie from 'js-cookie';
import { isBefore } from 'date-fns';
import { AUTH_TOKEN_KEY } from './constants';

export const getAuthToken = (): any => {
  const authCookie = Cookie.get('tuempresaenestonia_auth');
  if (authCookie) {
    const parsedCookie = JSON.parse(authCookie);
    if (
      parsedCookie &&
      parsedCookie.expires &&
      parsedCookie.access_token &&
      isBefore(new Date(), new Date(parsedCookie.expires))
    ) {
      return parsedCookie.access_token;
    }
  }
  return null;
};

export const readAuthToken = () => {
  return window.localStorage.getItem(AUTH_TOKEN_KEY) || '';
};
